<script setup>
import { onMounted, inject, defineProps, defineEmits, ref, onUnmounted } from "vue";

import Moeda from "../Utils/moeda.vue";
import Data from "../Utils/data.vue";
import { Converte } from "../../utils/converte";
import TipoDocumentoCreate from "../TiposDocumentos/TipoDocumentoCreate.vue";
import { TipoDocumento} from "../../resources/tipoDocumentoResource";
import { PermissaoService } from "../../services/permissaoService";

const snackBarApp = inject("snackBarApp");

const props = defineProps({
    propLancamento: {
        type: Object,
    },
});

const lancamento = ref({});
const dialogTiposDocumentos = ref(false);
const tiposDocumentos = ref([]);

const emit = defineEmits(['close-dialog', 'alterou-lancamento']);

function temPermissao(codigoModulo) {
    return PermissaoService.temPermissao(codigoModulo);
}


onMounted(() => {

    setTimeout(() => {
        document.getElementById("dataVencimento").focus();
    },0);
    
    getTiposDocumentos();
    
    lancamento.value = props.propLancamento;

    lancamento.value.valor = Converte.numberToCurrency(lancamento.value.valor);


});

async function getTiposDocumentos() {
    let start = 0;
    let limit = 999999;
    let sort = null;
    let primeiroRegistro = { idTipoDocumento: null, descricao: "" };

    const response = await TipoDocumento.get(start, limit, "", sort);
    if (response.status == 200) {
        tiposDocumentos.value = response.data.data;
        tiposDocumentos.value.unshift(primeiroRegistro);
    }

}


async function gravar() {
    if(Converte.stringToFloat(lancamento.value.valor) <= 0) {
        snackBarApp.value.open("O valor da parcela tem que ser maior que zero.", 6000);
        return;
    }

    if(lancamento.value.dataVencimento == '') {
        snackBarApp.value.open("A data de vencimento é obrigatória.", 6000);
        return;
    }
    
    snackBarApp.value.open("Lançamento alterado com sucesso.", 3000);

    emit('alterou-lancamento', lancamento.value);
}

const keydownHandler = (event) => {
    if(event.key === 'F7') {
        event.preventDefault();
    }

    if (event.key === 'F7' && document.activeElement.id === 'lancamentoTipoDocumento' && document.getElementById('lancamentoTipoDocumento').readOnly==false ) {
        event.preventDefault();
        document.getElementById('btnLancamentoNovoTipoDocumento').click();
    }
};

document.addEventListener('keydown', keydownHandler);

onUnmounted(() => {
    document.removeEventListener('keydown', keydownHandler);
});



</script>

<template>
    <v-card elevation="16">
        <v-card-title>
            <h2>Alteração de Parcela</h2>
        </v-card-title>
        <form ref="formAlteraLancamento">
            <v-card-text>
                <v-row>
                    <v-col cols="4">
                        <Data label="Data de Vencimento" id="dataVencimento" v-model="lancamento.dataVencimento" />
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            label="Documento"
                            id="documento"
                            type="text"
                            v-model="lancamento.documento"
                            maxlength="10"
                        />
                    </v-col>
                    <v-col cols="4">
                        <Moeda label="Valor" id="valor" v-model="lancamento.valor"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-textarea
                            label="Observações"
                            id="observacoes"
                            v-model="lancamento.observacoes"
                            rows="6"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-select
                            :items="tiposDocumentos"
                            item-title="descricao"
                            item-value="idTipoDocumento"
                            label="Tipo de Documento"
                            v-model="lancamento.idTipoDocumento"
                            id="lancamentoTipoDocumento"
                            return-object
                            v-tooltip="'F7-Cadastra um novo tipo de documento'"
                        >
                            <template v-slot:append>
                                <v-tooltip text="Cadastra um novo tipo de documento.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn
                                            v-bind="props"
                                            color="primary"
                                            @click="dialogTiposDocumentos = true"
                                            id="btnLancamentoNovoTipoDocumento"
                                            v-show="temPermissao('01.04.01')"
                                        >
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogTiposDocumentos" transition="dialog-top-transition">
                                    <TipoDocumentoCreate @cadastrouTipoDocumento="getTiposDocumentos()" @close-dialog="dialogTiposDocumentos = false" />
                                </v-dialog>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" variant="elevated" @click.prevent="gravar()" id="btnAlteraLancamento">Salvar</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    variant="elevated"
                    @click.prevent="emit('close-dialog')">
                    Fechar
                    </v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>
