import Http from './http';

export class PedidoCompra {
    static listar = function(start, limit, sort, dataInicial, dataFinal, abertos, cancelados, recebidos, idFornecedor, filtro, tipoFiltro, filtrarPor) {
        return Http.get('/Serv/PedidoCompra/PedidoCompra.php', {
            params: {
                'operacao': 'listar',
                'start':start,
                'limit':limit,
                'sort':sort,
                'filtrarPor': filtrarPor,
                'dataInicial': dataInicial,
                'dataFinal': dataFinal,
                'abertos': abertos,
                'cancelados': cancelados,
                'recebidos': recebidos,
                'idFornecedor': idFornecedor,
                'filtro': filtro,
                'tipoFiltro': tipoFiltro,
            }
        });
    };

    static gravar = function(dados) {
        return Http.post('/Serv/PedidoCompra/PedidoCompra.php?operacao=gravar', dados);
    };

    static proximoCodigo = function() {
        return Http.get('/Serv/PedidoCompra/PedidoCompra.php?operacao=proximocodigo');
    };

    static codigoExiste = function(codigo, idPedidoCompra) {
        let dados = new FormData();
        dados.append('codigo', codigo);
        dados.append('idPedidoCompra', idPedidoCompra);
        return Http.post('/Serv/PedidoCompra/PedidoCompra.php?operacao=codigoexiste', dados);
    };

    static getById = function(dados) {
        return Http.post('/Serv/PedidoCompra/PedidoCompra.php?operacao=getbyid', dados);
    };

    static excluir = function(dados) {
        return Http.post('/Serv/PedidoCompra/PedidoCompra.php?operacao=excluir', dados);
    };

    static cancelar = function(dados) {
        return Http.post('/Serv/PedidoCompra/PedidoCompra.php?operacao=cancelar', dados);
    };

    static addProduto = function(dados) {
        return Http.post('/Serv/PedidoCompra/PedidoCompra.php?operacao=addproduto', dados);
    };

    static listarProdutos = function(idPedidoCompra) {
        return Http.get('/Serv/PedidoCompra/PedidoCompra.php?operacao=listarprodutos', {
            params: {
                'idPedidoCompra':idPedidoCompra,
            }
        });
    };

    static totais = function(idPedidoCompra) {
        return Http.get('/Serv/PedidoCompra/PedidoCompra.php?operacao=totais', {
            params: {
                'idPedidoCompra':idPedidoCompra,
            }
        });
    };

    static removeProduto = function(idProdutoPedidoCompra) {
        let dados = new FormData();
        dados.append('idProdutoPedidoCompra', idProdutoPedidoCompra);

        return Http.post('/Serv/PedidoCompra/PedidoCompra.php?operacao=removeproduto', dados);
    };

    static updateProduto = function(dados) {
        return Http.post('/Serv/PedidoCompra/PedidoCompra.php?operacao=updateproduto', dados);
    };
    static getObservacoes = function(dados) {
        return Http.post('/Serv/PedidoCompra/PedidoCompra.php?operacao=getobservacoes', dados);
    };
    static gravarObservacoes = function(dados) {
        return Http.post('/Serv/PedidoCompra/PedidoCompra.php?operacao=gravarobservacoes', dados);
    };
    static faturaAVista = function(dados) {
        return Http.post('/Serv/PedidoCompra/PedidoCompra.php?operacao=faturaavista', dados);
    };
    static faturaAPrazo = function(dados) {
        return Http.post('/Serv/PedidoCompra/PedidoCompra.php?operacao=faturaaprazo', dados);
    };
    static faturaCheque = function(dados) {
        return Http.post('/Serv/PedidoCompra/PedidoCompra.php?operacao=faturacheque', dados);
    };
    static faturaTransferencia = function(dados) {
        return Http.post('/Serv/PedidoCompra/PedidoCompra.php?operacao=faturatransferencia', dados);
    };

    static getParcelas = function(idPedidoCompra) {
        return Http.get('/Serv/PedidoCompra/PedidoCompra.php', {
            params: {
                'operacao': 'getparcelas',
                'idPedidoCompra':idPedidoCompra,
            }
        });
    };

    static getCheques = function(idPedidoCompra) {
        return Http.get('/Serv/PedidoCompra/PedidoCompra.php', {
            params: {
                'operacao': 'getcheques',
                'idPedidoCompra':idPedidoCompra,
            }
        });
    };

    static recebe = function(dados) {
        return Http.post('/Serv/PedidoCompra/PedidoCompra.php?operacao=recebe', dados);
    };
    static duplica = function(dados) {
        return Http.post('/Serv/PedidoCompra/PedidoCompra.php?operacao=duplica', dados);
    };
    static alterarCodigo = function(dados) {
        return Http.post('/Serv/PedidoCompra/PedidoCompra.php?operacao=alterarcodigo', dados);
    };


}