<script setup>
import { ref, onMounted, inject, onUnmounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { PedidoCompra } from "../../resources/pedidoCompraResource";
import { Fornecedor } from "../../resources/fornecedorResource";
import { useField, useForm } from "vee-validate";
import { string, number, addMethod, object } from "yup";
import { usePermissoes } from "../../store/permissoes";
import FornecedorCreate from "../Fornecedores/FornecedorCreate.vue";
import FornecedorBusca from "../Fornecedores/FornecedorBusca.vue";
import ProdutoPedidoCompra from "./ProdutoPedidoCompra.vue";
import Data from "../Utils/data.vue";
import { Converte } from "../../utils/converte";
import UpdateProdutoPedidoCompra from "./UpdateProdutoPedidoCompra.vue";
import ObservacoesPedidoCompra from "./ObservacoesPedidoCompra.vue";
import FormaPagamentoPedidoCompra from "./FormaPagamentoPedidoCompra.vue";
import { quantidadeProdutoFormat, valorFormat, pCustoProdutoFormat } from "../../services/filtros";
import AVistaPedidoCompra from "./AVistaPedidoCompra.vue";
import APrazoPedidoCompra from "./APrazoPedidoCompra.vue";
import ChequePedidoCompra from "./ChequePedidoCompra.vue";
import TransferenciaPedidoCompra from "./TransferenciaPedidoCompra.vue";

const isRealValidation = ref(null);
const validationCodigo = ref(null);
const isReadOnly = ref(false);
const isReadOnlyDadosProduto = ref(false);
const route = useRoute();
const router = useRouter();
const dialogFornecedor = ref(false);
const dialogBuscaFornecedor = ref(false);
const dialogUpdateProdutoPedidoCompra = ref(false);
const dialogObservacoes = ref(false);
const dialogFormaPagamento = ref(false);
const dialogFaturaAVista = ref(false);
const dialogFaturaAPrazo = ref(false);
const dialogFaturaCheque = ref(false);
const dialogFaturaTransferencia = ref(false);
const isReadOnlyDataEntrega = ref(false);
const operacao = ref('F');

let showBtnGravar = ref(true);
const produtosPedidoCompra = ref([]);

const situacao = ref("");
const valorTotal = ref("Valor Total dos Produtos: R$ 0,00")

const snackBarApp = inject("snackBarApp");

const permissoesStore = usePermissoes();

addMethod(number, "codigo_pedido_compra_existe", function codigoPedidoCompraExiste(message) {
    return number().test(
        "codigo-pedido-compra-existe", // Name
        message, // Msg
        function (codigo) {
            if (isRealValidation.value == null) {
                return true;
            }
            if (!isRealValidation.value) {
                return validationCodigo.value;
            }
            isRealValidation.value = false;
            validationCodigo.value = false;

            return new Promise((resolve) => {
                PedidoCompra.codigoExiste(codigo, pedidoCompra.value.idPedidoCompra).then((res) => {
                    if (res.data == false) {
                        validationCodigo.value = true;
                        resolve(true);
                    }
                    resolve(false);
                });
            });
        }
    );
});


const validationSchema = object({
    pedidoCompra: object({
        idPedidoCompra: undefined,
        codigo: number()
            .min(1)
            .max(999999999)
            .codigo_pedido_compra_existe("Esse código já está sendo usado por outro pedido de compra, tente outro código.")
            .typeError("O código deve ser um número")
            .label("O código"),
        data: string().required("A data é obrigatória").label("A data"),
        idFornecedor: undefined,
        codigoFornecedor: undefined,
        razaoFornecedor: undefined,
        prazoEntrega: undefined,
        documento: undefined,
        dataEntrega: undefined,
        dataFaturamento: undefined,
        situacao: undefined,
        formaPagamento: undefined,

    }).required(),
});
const { handleSubmit, errors, setValues } = useForm({ validationSchema });

const pedidoCompra = ref({
    idPedidoCompra: useField("pedidoCompra.idPedidoCompra").value,
    codigo: useField("pedidoCompra.codigo").value,
    data: useField("pedidoCompra.data").value,
    idFornecedor: useField("pedidoCompra.idFornecedor").value,
    codigoFornecedor: useField("pedidoCompra.codigoFornecedor").value,
    razaoFornecedor: useField("pedidoCompra.razaoFornecedor").value,
    prazoEntrega: useField("pedidoCompra.prazoEntrega").value,
    documento: useField("pedidoCompra.documento").value,
    dataEntrega: useField("pedidoCompra.dataEntrega").value,
    dataFaturamento: useField("pedidoCompra.dataFaturamento").value,
    situacao: useField("pedidoCompra.situacao").value,
    formaPagamento: useField("pedidoCompra.formaPagamento").value,
});

const submit = handleSubmit((values, actions) => {
    gravar(values, actions);
});


onMounted(() => {
    cleanForm();
    isReadOnly.value = false;

    if (route.name == "PedidosCompras.Continuar" || route.name == "PedidosCompras.Show") {
        getPedidoCompra(route.params.id);
    } else {
        document.getElementById("codigo").focus();
        isReadOnlyDadosProduto.value = true;
    }

    if (route.name == "PedidosCompras.Show") {
        isReadOnly.value = true;
        isReadOnlyDadosProduto.value = true;
        isReadOnlyDataEntrega.value = true;
    }

    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == "D" || tipo == "P") && validade == "N") {
        showBtnGravar.value = false;
    }
});

function cleanForm() {
    if (route.name == "PedidosCompras.Create") {
        pedidoCompra.value.idPedidoCompra = null;
        proximoCodigo();
    }
    pedidoCompra.value.data = new Date().toLocaleDateString("pt-BR");
    pedidoCompra.value.idFornecedor = null;
    pedidoCompra.value.codigoFornecedor = "";
    pedidoCompra.value.razaoFornecedor = "";
    pedidoCompra.value.prazoEntrega = "";
    pedidoCompra.value.documento = "";
    pedidoCompra.value.situacao = "A";
    pedidoCompra.value.formaPagamento = "";
    pedidoCompra.value.dataEntrega = "";
    pedidoCompra.value.dataFaturamento = "";
}


async function gravar(values) {
    let dados = new FormData();

    values.pedidoCompra.data = Converte.datePtBrToIso(values.pedidoCompra.data);

    if (values.pedidoCompra.idFornecedor == "") {
        values.pedidoCompra.idFornecedor = null;
    }

    let prazoEntrega = '';

    if (values.pedidoCompra.prazoEntrega == '') {
        prazoEntrega = null;
    } else {
        prazoEntrega = Converte.datePtBrToIso(values.pedidoCompra.prazoEntrega);
    }


    values.pedidoCompra.prazoEntrega = prazoEntrega;

    dados.append("dados", JSON.stringify(values.pedidoCompra));

    if (route.name == 'PedidosCompras.Create' || route.name == 'PedidosCompras.Continuar') {
        try {
            const response = await PedidoCompra.gravar(dados);

            if (response.status == 200) {
                if (response.data.success == false) {
                    snackBarApp.value.open("O pedido de compra não pode ser cadastrado, mensagem de erro: " + response.data.message, 6000);
                    return;
                }


                if (route.name == 'PedidosCompras.Create') {
                    let idPedidoCompra = response.data.idPedidoCompra;
                    pedidoCompra.value.idPedidoCompra = idPedidoCompra;
                    snackBarApp.value.open("Novo pedido de compra gravado com sucesso.");
                    isReadOnlyDadosProduto.value = false;
                    router.push({ name: 'PedidosCompras.Continuar', params: { id: idPedidoCompra } });

                    setTimeout(function () {
                        document.getElementById("codigoProduto").focus();
                    }, 200);

                }

                if (route.name == 'PedidosCompras.Continuar') {
                    isReadOnlyDadosProduto.value = false;
                    snackBarApp.value.open("Pedido de compra gravado com sucesso.");
                }
            }
        } catch (err) {
            snackBarApp.value.open("Erro, mensagem de erro: " + err);
        }
    }
}

async function getPedidoCompra(id) {
    let dados = new FormData();

    if (route.name == "PedidosCompras.Continuar") {
        dados.append("codigoModulo", "03.13.02");
    } else if (route.name == "PedidosCompras.Show") {
        dados.append("codigoModulo", "03.13.03");
    }

    dados.append("idPedidoCompra", id);

    try {
        let response = await PedidoCompra.getById(dados)
        if (response.status == 200) {

            response.data.data.data = Converte.dateIsoToPtBr(response.data.data.data);
            if (response.data.data.prazoEntrega !== null) {
                response.data.data.prazoEntrega = Converte.dateIsoToPtBr(response.data.data.prazoEntrega);
            }
            if (response.data.data.dataEntrega !== null) {
                response.data.data.dataEntrega = Converte.dateIsoToPtBr(response.data.data.dataEntrega);
            }

            if (response.data.data.dataFaturamento !== null) {
                response.data.data.dataFaturamento = Converte.dateIsoToPtBr(response.data.data.dataFaturamento);
            }

            if (response.data.data.dataTransferencia !== null) {
                response.data.data.dataTransferencia = Converte.dateIsoToPtBr(response.data.data.dataTransferencia);
            }

            if (response.data.data.formaPagamento == null) {
                response.data.data.formaPagamento = "";
            }


            response.data.data.valor = Converte.numberToCurrency(response.data.data.valor);

            let sit = response.data.data.situacao;
            if (sit == "A") {
                if (response.data.data.formaPagamento == "") {
                    situacao.value = "Situação do pedido de compra: Em Aberto";
                }
                else {
                    if (response.data.data.formaPagamento == "CAI") {
                        situacao.value = "Situação do pedido de compra: Faturado a Vista";
                    }
                    if (response.data.data.formaPagamento == "PRA") {
                        situacao.value = "Situação do pedido de compra: Faturado a Prazo";
                    }
                    if (response.data.data.formaPagamento == "CHE") {
                        situacao.value = "Situação do pedido de compra: Faturado com Cheque";
                    }
                    if (response.data.data.formaPagamento == "CON") {
                        situacao.value = "Situação do pedido de compra: Faturado com PIX/Transferência";
                    }

                }

            } else if (sit == 'R') {
                if (response.data.data.formaPagamento == 'CAI') {
                    situacao.value = "Situação do pedido de compra: Recebido e Faturado a Vista";
                }
                if (response.data.data.formaPagamento == 'PRA') {
                    situacao.value = "Situação do pedido de compra: Recebido e Faturado a Prazo";
                }
                if (response.data.data.formaPagamento == 'CHE') {
                    situacao.value = "Situação do pedido de compra: Recebido e Faturado com Cheque";
                }
                if (response.data.data.formaPagamento == 'CON') {
                    situacao.value = "Situação do pedido de compra: Recebido e Faturado com PIX/Transferência";
                }

            } else if (sit == 'C') {
                situacao.value = "Situação do pedido de compra: Cancelado";
            }

            if (route.name == "PedidosCompras.Continuar" && response.data.data.formaPagamento !== "") {
                isReadOnly.value = true;
                isReadOnlyDataEntrega.value = false;
                isReadOnlyDadosProduto.value = true;

            }


            setValues({ pedidoCompra: response.data.data });
            getProdutos(response.data.data.idPedidoCompra);
        }
    }
    catch (err) {
        console.log(err);
    }
}

async function proximoCodigo() {
    try {
        const response = await PedidoCompra.proximoCodigo();
        if (response.status == 200) {
            pedidoCompra.value.codigo = response.data;
        }
    } catch (err) {
        console.log(err);
    }
}

async function getFornecedor() {
    pedidoCompra.value.idFornecedor = null;
    pedidoCompra.value.razaoFornecedor = "";

    if (pedidoCompra.value.codigoFornecedor == null || pedidoCompra.value.codigoFornecedor.toString().trim() == "") {
        return;
    }

    let dados = new FormData();
    dados.append("codigo", pedidoCompra.value.codigoFornecedor);
    dados.append("getInativos", false);
    dados.append("codigoModulo", "03.13");

    try {
        let response = await Fornecedor.getByCodigo(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                if (response.data.message == "inativo") {
                    pedidoCompra.value.codigoFornecedor = "";
                    document.getElementById("codigoFornecedor").focus();
                    snackBarApp.value.open("Um fornecedor inativo não pode gerar novos pedidos de compras.", 6000);
                    return;
                }
                if (response.data.message == "bloqueado") {
                    pedidoCompra.value.codigoFornecedor = "";
                    document.getElementById("codigoFornecedor").focus();
                    snackBarApp.value.open("Um fornecedor bloqueado não pode gerar novos pedidos de compras.", 6000);
                    return;
                }
                pedidoCompra.value.codigoFornecedor = "";
                document.getElementById("codigoFornecedor").focus();
                snackBarApp.value.open("Fornecedor não encontrado, tente outro código ou faça uma busca.", 6000);
                return;
            }
            pedidoCompra.value.idFornecedor = response.data.data.idFornecedor;
            pedidoCompra.value.razaoFornecedor = response.data.data.razao;
        }
    } catch (err) {
        console.log(err);
    }
}

function selecionouFornecedor(fornecedor) {
    if (fornecedor == null) {
        return;
    }

    pedidoCompra.value.codigoFornecedor = fornecedor.codigo;
    pedidoCompra.value.razaoFornecedor = fornecedor.razao;
    pedidoCompra.value.idFornecedor = fornecedor.idFornecedor;

    getFornecedor();
    dialogBuscaFornecedor.value = false;

    setTimeout(() => {
        document.getElementById("prazoEntrega").focus();
    }, 100);
}

function cadastrouFornecedor(codigoFornecedor) {
    if (codigoFornecedor == null) {
        return;
    }

    pedidoCompra.value.codigoFornecedor = codigoFornecedor;

    getFornecedor();
}

async function totais(idPedidoCompra) {
    try {
        const response = await PedidoCompra.totais(idPedidoCompra);
        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open("Erro ao calcular os totais do pedido de compra, mensagem: " + response.data.message, 6000);
                return;
            }

            valorTotal.value = "Valor Total dos Produtos: " + valorFormat(response.data.data.total);
        }
    } catch (err) {
        console.log(err);
    }
}

function faturouPedidoCompra() {
    dialogFormaPagamento.value = false;
    cleanForm();
    pedidoCompra.value.idPedidoCompra = null;
    proximoCodigo();
    totais(null);
    produtosPedidoCompra.value = [];
    router.push({ name: "PedidosCompras.Create" });
}

function addProduto(produtoPedidoCompra) {
    produtoPedidoCompra.valorUnitario = Converte.stringToFloat(produtoPedidoCompra.valorUnitario).toString();
    produtosPedidoCompra.value.push(produtoPedidoCompra);
    totais(route.params.id);
}

async function getProdutos(idPedidoCompra) {
    try {
        const response = await PedidoCompra.listarProdutos(idPedidoCompra);
        if (response.status == 200) {
            produtosPedidoCompra.value = response.data.data;
            totais(idPedidoCompra);
        }
    } catch (err) {
        console.log(err);
    }
}

async function removeProduto(idProdutoPedidoCompra) {
    try {
        const response = await PedidoCompra.removeProduto(idProdutoPedidoCompra);
        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open("O item não pode ser excluído do pedido de compra, mensagem: " + response.data.message, 6000);
                return;
            }

            snackBarApp.value.open("Item excluído com sucesso do pedido de compra.", 1500);
            getProdutos(route.params.id);
            totais(route.params.id);
        }
    } catch (err) {
        console.log(err);
    }
}

function abreInformacoesFaturamento() {
    if (pedidoCompra.value.formaPagamento == "CAI") {
        dialogFaturaAVista.value = true;
    }
    if (pedidoCompra.value.formaPagamento == "PRA") {
        dialogFaturaAPrazo.value = true;
    }
    if (pedidoCompra.value.formaPagamento == "CHE") {
        dialogFaturaCheque.value = true;
    }
    if (pedidoCompra.value.formaPagamento == "CON") {
        dialogFaturaTransferencia.value = true;
    }

}

async function abreReceberPedido() {
    if (pedidoCompra.value.formaPagamento == "") {
        dialogFormaPagamento.value = true;
        operacao.value = 'R';
        return;
    }

    let dataEntrega = null;
    if (pedidoCompra.value.dataEntrega == '' || pedidoCompra.value.dataEntrega == null) {
        dataEntrega = null;
    } else {
        dataEntrega = Converte.datePtBrToIso(pedidoCompra.value.dataEntrega);
    }

    let auxPedidoCompra = pedidoCompra.value;

    auxPedidoCompra.dataEntrega = dataEntrega;

    let dados = new FormData();
    dados.append('dados', JSON.stringify(auxPedidoCompra));
    try {
        let response = await PedidoCompra.recebe(dados)
        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open("O pedido de compra não pode ser recebido, mensagem: " + response.data.message, 6000);
                return;
            }
            snackBarApp.value.open("Pedido de compra recebido com sucesso.", 1500);

            isReadOnly.value = false;
            cleanForm();
            pedidoCompra.value.idPedidoCompra = null;
            proximoCodigo();
            produtosPedidoCompra.value = [];
            router.push({ "name": "PedidosCompras.Create" });

            document.getElementById('codigo').focus();
        }
    } catch (err) {
        console.log(err);
    }
}

function faturarPedidoCompra() {
    operacao.value = 'F';
    dialogFormaPagamento.value = true;
}

const keydownHandler = (event) => {
    if(event.key === 'F5') {
        event.preventDefault();
    }

    if(event.key === 'F7') {
        event.preventDefault();
    }

    if (event.key === 'F5' && document.activeElement.id === 'codigoFornecedor' && document.getElementById('codigoFornecedor').readOnly==false ) {
        event.preventDefault();
        document.getElementById('btnBuscaFornecedor').click();
    }

    if (event.key === 'F7' && document.activeElement.id === 'codigoFornecedor' && document.getElementById('codigoFornecedor').readOnly==false ) {
        event.preventDefault();
        document.getElementById('btnNovoFornecedor').click();
    }

    if (event.key === 'F5' && ( document.activeElement.id === 'codigoBarras' || 
        document.activeElement.id === 'codigoProduto')) {
        event.preventDefault();
        document.getElementById('btnBuscaProduto').click();
    }

    if (event.key === 'F7' && ( document.activeElement.id === 'codigoBarras' || 
        document.activeElement.id === 'codigoProduto')) {
        event.preventDefault();
        document.getElementById('btnNovoProduto').click();
    }
};

document.addEventListener('keydown', keydownHandler);

onUnmounted(() => {
    document.removeEventListener('keydown', keydownHandler);
});


</script>

<template>
    <v-card elevetion="16">
        <v-card-title>
            <h2 v-show="$route.name == 'PedidosCompras.Create'">Novo Pedido de Compra</h2>
        </v-card-title>
        <v-card-text>
            <v-card elevation="16" class="mb-8">
                <v-card-title>
                    <h3>Dados do Pedido de Compra</h3>
                </v-card-title>
                <form ref="formPedidoCompra" @submit.prevent="submit">
                    <v-card-text>
                        <v-row>
                            <v-col cols="3">
                                <v-text-field label="Código" id="codigo" type="text" v-model="pedidoCompra.codigo"
                                    :error-messages="errors['pedidoCompra.codigo']" maxlength="9" @blur="(e) => {
                                            isRealValidation = true;
                                            pedidoCompra.codigo = e.target.value;
                                        }
                                        " :readonly="isReadOnly" />
                            </v-col>
                            <v-col cols="3">
                                <Data label="Data Emissão" id="data" v-model="pedidoCompra.data"
                                    :error-messages="errors['pedidoCompra.data']" :readonly="isReadOnly"></Data>
                            </v-col>
                            <v-col cols="3">
                                <Data label="Prazo para Entrega" id="prazoEntrega" v-model="pedidoCompra.prazoEntrega"
                                    :error-messages="errors['pedidoCompra.prazoEntrega']" :readonly="isReadOnly"></Data>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field label="Documento" id="documento" type="text"
                                    v-model="pedidoCompra.documento" maxlength="10"
                                    :error-messages="errors['pedidoCompra.documento']"
                                    :readonly="isReadOnly && isReadOnlyDataEntrega" />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="3">
                                <v-text-field label="Código do Fornecedor" id="codigoFornecedor" type="text"
                                    v-model="pedidoCompra.codigoFornecedor" maxlength="10"
                                    :error-messages="errors['pedidoCompra.codigoFornecedor']" @blur="getFornecedor()"
                                    :readonly="isReadOnly"
                                    v-tooltip="isReadOnly ? 'código do fornecedor' : 'F5-Abre a busca de fornecedores; F7-Cadastra um novo fornecedor'" />
                            </v-col>
                            <v-col>
                                <v-text-field label="Razão Social/Nome do Fornecedor" id="razaoFornecedor" type="text"
                                    v-model="pedidoCompra.razaoFornecedor" maxlength="50"
                                    :error-messages="errors['pedidoCompra.razaoFornecedor']" readonly tabindex="-1">
                                    <template v-slot:append
                                        v-if="route.name !== 'PedidosCompras.Show' && pedidoCompra.dataFaturamento == ''">
                                        <v-tooltip text="Faz a busca de um fornecedor por nome fantasia/cnpj.">
                                            <template v-slot:activator="{ props }">
                                                <v-btn v-bind="props" color="primary"
                                                    @click="dialogBuscaFornecedor = true" id="btnBuscaFornecedor">
                                                    <v-icon>mdi-magnify</v-icon>
                                                </v-btn>
                                            </template>
                                        </v-tooltip>
                                        <v-dialog v-model="dialogBuscaFornecedor" transition="dialog-top-transition">
                                            <FornecedorBusca @selecionouFornecedor="selecionouFornecedor"
                                                @close-dialog="dialogBuscaFornecedor = false" :select-inativo-bloqueado=false />
                                        </v-dialog>
                                        &nbsp;
                                        <v-tooltip text="Cadastra um novo fornecedor.">
                                            <template v-slot:activator="{ props }">
                                                <v-btn v-bind="props" color="primary" @click="dialogFornecedor = true"
                                                    id="btnNovoFornecedor">
                                                    <v-icon>mdi-invoice-text-plus</v-icon>
                                                </v-btn>
                                            </template>
                                        </v-tooltip>
                                        <v-dialog v-model="dialogFornecedor" transition="dialog-top-transition">
                                            <FornecedorCreate @cadastrouFornecedor="cadastrouFornecedor"
                                                @close-dialog="dialogFornecedor = false" />
                                        </v-dialog>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="3"
                                v-show="pedidoCompra.dataFaturamento !== '' && pedidoCompra.dataFaturamento !== null">
                                <Data label="Data Faturamento" id="dataFaturamento"
                                    v-model="pedidoCompra.dataFaturamento" readonly tabindex="-1">
                                </Data>
                            </v-col>
                            <v-col cols="3"
                                v-show="pedidoCompra.dataFaturamento !== '' && pedidoCompra.dataFaturamento !== null">
                                <Data label="Data Entrega" id="dataEntrega" v-model="pedidoCompra.dataEntrega"
                                    :readonly="isReadOnly && isReadOnlyDataEntrega">
                                </Data>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary"
                            v-show="$route.name !== 'PedidosCompras.Show' && showBtnGravar && (pedidoCompra.dataFaturamento == '' || pedidoCompra.dataFaturamento === null)"
                            variant="elevated" type="submit" id="btnPedidosComprasGravar">Gravar</v-btn>
                    </v-card-actions>
                </form>
            </v-card>


            <ProdutoPedidoCompra v-show="route.name == 'PedidosCompras.Continuar' && pedidoCompra.formaPagamento == ''"
                :idPedidoCompra="pedidoCompra.idPedidoCompra" :isReadOnlyDadosProduto="isReadOnlyDadosProduto"
                @addProduto="addProduto"> </ProdutoPedidoCompra>

            <v-card elevation="16" class="mb-8" v-show="route.name !== 'PedidosCompras.Create'">
                <v-table density="compact">
                    <thead>
                        <tr>
                            <th class="text-center"
                                v-show="route.name == 'PedidosCompras.Continuar' && pedidoCompra.formaPagamento == ''">
                                Ações
                            </th>
                            <th>Cód.Produto</th>
                            <th>Descrição do Produto</th>
                            <th>Unid.</th>
                            <th>Quantidade</th>
                            <th>Valor Unitário</th>
                            <th>Valor Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="o in produtosPedidoCompra" :key="o">
                            <td v-show="route.name == 'PedidosCompras.Continuar' && pedidoCompra.formaPagamento == ''">
                                <v-tooltip text="Altera o item.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn size="small" v-bind="props" variant="text"
                                            icon="mdi-invoice-edit-outline"
                                            @click.prevent="auxProdutoPedidoCompra = Object.assign({}, o);; dialogUpdateProdutoPedidoCompra = true;">
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-tooltip text="Exclui o item.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn size="small" v-bind="props" variant="text" icon="mdi-trash-can"
                                            @click.prevent="removeProduto(o.idProdutoPedidoCompra)">
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                            </td>
                            <td>{{ o.codigoProduto }}</td>
                            <td>{{ o.descricaoProduto }}</td>
                            <td>{{ o.unidadeProduto }}</td>
                            <td>{{ quantidadeProdutoFormat(o.quantidade) }}</td>
                            <td>{{ pCustoProdutoFormat(o.valorUnitario) }}</td>
                            <td>{{ valorFormat(o.valorTotal) }}</td>
                        </tr>
                    </tbody>
                </v-table>

                <v-dialog v-model="dialogUpdateProdutoPedidoCompra" transition="dialog-top-transition">
                    <UpdateProdutoPedidoCompra :produtoPedidoCompra=auxProdutoPedidoCompra
                        @close-dialog="dialogUpdateProdutoPedidoCompra = false"
                        @alterou-produto="dialogUpdateProdutoPedidoCompra = false; getProdutos(route.params.id)" />
                </v-dialog>

                <v-dialog v-model="dialogObservacoes" transition="dialog-top-transition">
                    <ObservacoesPedidoCompra @close-dialog="dialogObservacoes = false" />
                </v-dialog>

                <v-dialog v-model="dialogFormaPagamento" transition="dialog-top-transition" max-width="750">
                    <FormaPagamentoPedidoCompra @close-dialog="dialogFormaPagamento = false"
                        @faturou-pedido-compra="faturouPedidoCompra()" :pedidoCompra="pedidoCompra"
                        :operacao="operacao" />
                </v-dialog>

                <v-dialog v-model="dialogFaturaAVista" transition="dialog-top-transition">
                    <AVistaPedidoCompra :propPedidoCompra="pedidoCompra" operacao="C"
                        @close-dialog="dialogFaturaAVista = false">
                    </AVistaPedidoCompra>
                </v-dialog>

                <v-dialog v-model="dialogFaturaAPrazo" transition="dialog-top-transition">
                    <APrazoPedidoCompra :propPedidoCompra="pedidoCompra" operacao="C"
                        @close-dialog="dialogFaturaAPrazo = false">
                    </APrazoPedidoCompra>
                </v-dialog>

                <v-dialog v-model="dialogFaturaCheque" transition="dialog-top-transition">
                    <ChequePedidoCompra :propPedidoCompra="pedidoCompra" operacao="C"
                        @close-dialog="dialogFaturaCheque = false">
                    </ChequePedidoCompra>
                </v-dialog>

                <v-dialog v-model="dialogFaturaTransferencia" transition="dialog-top-transition">
                    <TransferenciaPedidoCompra :propPedidoCompra="pedidoCompra" operacao="C"
                        @close-dialog="dialogFaturaTransferencia = false">
                    </TransferenciaPedidoCompra>
                </v-dialog>
            </v-card>

            <v-row v-show="route.name !== 'PedidosCompras.Create'">
                <v-col cols="6">
                    <h2>{{ situacao }}</h2>
                </v-col>
                <v-col cols="6">
                    <h2 class="text-right">{{ valorTotal }}</h2>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-btn color="primary" variant="elevated" @click="dialogObservacoes = true"
                v-show="route.name !== 'PedidosCompras.Create'">
                Observações
            </v-btn>

            <v-btn color="primary" variant="elevated" @click="faturarPedidoCompra()"
                v-show="route.name == 'PedidosCompras.Continuar' && pedidoCompra.formaPagamento == '' && produtosPedidoCompra.length > 0">
                Faturar Pedido
            </v-btn>

            <v-btn color="primary" variant="elevated" @click="abreInformacoesFaturamento"
                v-show="pedidoCompra.formaPagamento !== '' && pedidoCompra.situacao !== 'C'">
                Informações sobre Faturamento
            </v-btn>

            <v-btn color="primary" variant="elevated" @click="abreReceberPedido()"
                v-show="route.name == 'PedidosCompras.Continuar' && pedidoCompra.situacao == 'A' && produtosPedidoCompra.length > 0">
                Receber Pedido
            </v-btn>

            <v-spacer></v-spacer>
            <v-btn color="primary" variant="elevated" :to="{ name: 'PedidosCompras.List' }">
                Voltar
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
