import { useCasasDecimais } from '../store/casasDecimais';

function pCustoProdutoFormat(value) {
    const casasDecimaisStore = useCasasDecimais();

    if (!value) return '';

    let number = '';
    let result = '';
    value = value.replace(',','.');

    var numberRegex = value.toString().match(/[-+]?\d+(\.\d*)?/g);
    number = numberRegex ? numberRegex[0] : numberRegex;
    if(number !== null) {
        number = number.replace(',','.');
    }

    result = new Intl.NumberFormat('pt-BR', {
        maximumFractionDigits: casasDecimaisStore.pCustoProduto,
        minimumFractionDigits: casasDecimaisStore.pCustoProduto,
        style: 'currency',
        currency: 'BRL'
    }).format(number).replace(/^(\D+)/, '$1 ');

    return result;

}

function valorFormat(value) {
    if(value == '' || value == null) {
        return 'R$ 0,00';
    }
    if (!value) return '';

    let number = '';
    let result = '';
    value = value.replace(',','.');

    var numberRegex = value.toString().match(/[-+]?\d+(\.\d*)?/g);
    number = numberRegex ? numberRegex[0] : numberRegex;

    if(number !== null) {
        number = number.replace(',','.');
    }

    result = new Intl.NumberFormat('pt-BR', {
        maximumFractionDigits:2,
        minimumFractionDigits:2,
        style: 'currency',
        currency: 'BRL'
    }).format(number).replace(/^(\D+)/, '$1 ');

    return result;

}


function quantidadeFormat(value) {
    if (!value) return '';

    let number = '';
    let result = '';
    value = value.replace(',','.');

    var numberRegex = value.toString().match(/[-+]?\d+(\.\d*)?/g);
    number = numberRegex ? numberRegex[0] : numberRegex;
    if(number !== null) {
        number = number.replace(',','.');
    }

    result = new Intl.NumberFormat('pt-BR', {
        maximumFractionDigits:3,
        minimumFractionDigits:3,
        style: 'decimal'
    }).format(number);

    return result;

}

function  simNaoFormat(value) {
    if (!value) return '';

    if(value == 'S') {
        return 'Sim';
    }
    else
    {
        return 'Não';
    }
}

function  ativoInativoBloqueadoFormat(value) {
    if (!value) return '';

    if(value == 'A') {
        return 'Ativo';
    }
    else if(value == 'I')
    {   
        return 'Inativo';
    }
    {
        return 'Bloqueado';
    }
}


function valorFormatDuasCasas(value) {
    if (!value) return '';

    let number = '';
    let result = '';
    value = value.replace(',','.');

    var numberRegex = value.toString().match(/[-+]?\d+(\.\d*)?/g);
    number = numberRegex ? numberRegex[0] : numberRegex;
    if(number !== null) {
        number = number.replace(',','.');
    }

    result = new Intl.NumberFormat('pt-BR', {
        maximumFractionDigits:2,
        minimumFractionDigits:2,
        style: 'currency',
        currency: 'BRL'
    }).format(number).replace(/^(\D+)/, '$1 ');

    return result;

}


function quantidadeProdutoFormat(value) {
    const casasDecimaisStore = useCasasDecimais();

    if (!value) return '';

    let number = '';
    let result = '';
    value = value.replace(',','.');

    var numberRegex = value.toString().match(/[-+]?\d+(\.\d*)?/g);
    number = numberRegex ? numberRegex[0] : numberRegex;
    if(number !== null) {
        number = number.replace(',','.');
    }

    result = new Intl.NumberFormat('pt-BR', {
        maximumFractionDigits: casasDecimaisStore.quantidadeProduto,
        minimumFractionDigits: casasDecimaisStore.quantidadeProduto,
        style: 'decimal'
    }).format(number);

    return result;

}


function margemFormat(value) {
    if (!value) return '';

    let number = '';
    let result = '';
    value = value.replace(',','.');

    var numberRegex = value.toString().match(/[-+]?\d+(\.\d*)?/g);
    number = numberRegex ? numberRegex[0] : numberRegex;
    if(number !== null) {
        number = number.replace(',','.');
    }

    result = new Intl.NumberFormat('pt-BR', {
        maximumFractionDigits:2,
        minimumFractionDigits:2,
        style: 'decimal'
    }).format(number);

    return result+' %';

}


function quantidadeServicoFormat(value) {
    const casasDecimaisStore = useCasasDecimais();

    if (!value) return '';

    let number = '';
    let result = '';
    value = value.replace(',','.');

    var numberRegex = value.toString().match(/[-+]?\d+(\.\d*)?/g);
    number = numberRegex ? numberRegex[0] : numberRegex;
    if(number !== null) {
        number = number.replace(',','.');
    }

    result = new Intl.NumberFormat('pt-BR', {
        maximumFractionDigits:casasDecimaisStore.quantidadeServico,
        minimumFractionDigits:casasDecimaisStore.quantidadeServico,
        style: 'decimal'
    }).format(number);

    return result;

}

function debitoCreditoFormat(value) {
    if (!value) return '';

    if(value == 'D') {
        return 'Débito';
    }
    else
    {
        return 'Crédito';
    }

}

    
function dateFormat(value) {
    if (!value || value === '') return ''; // Return empty string if value is null or empty

    return value.slice(8,10)+'/'+value.slice(5,7)+'/'+value.slice(0,4);
    
}


function porcentagemFormat(value, casasDecimais=2) {
    if (!value) return '';

    let number = '';
    let result = '';
    value = value.replace(',','.');

    var numberRegex = value.toString().match(/[-+]?\d+(\.\d*)?/g);
    number = numberRegex ? numberRegex[0] : numberRegex;
    if(number !== null) {
        number = number.replace(',','.');
    }

    result = new Intl.NumberFormat('pt-BR', {
        maximumFractionDigits:casasDecimais,
        minimumFractionDigits:casasDecimais,
        style: 'decimal'
    }).format(number);

    return result+' %';
}



function localCreditoCartaoFormat(value) {
    if (!value) return '';

    if(value == 'CAI') {
        return 'Caixa';
    }
    else if(value == 'CON')
    {
        return 'Conta Bancária';
    }
    else {
        return '';
    }
}

function  formaRecebimentoAdiantamentoFormat(value) {
    if (!value) return '';

    if(value == 'DIN') {
        return 'Dinheiro';
    }
    else if(value == 'CHE')
    {   
        return 'Cheque';
    }
    else if(value == 'CAR')
    {
        return 'Cartão/Convênio';
    }
    else if(value == 'DEP')
    {
        return 'PIX/Depósito/Transferência';
    }
    else 
    {
        return '';
    }
}


export { pCustoProdutoFormat, valorFormat, quantidadeFormat, simNaoFormat, ativoInativoBloqueadoFormat, valorFormatDuasCasas, quantidadeProdutoFormat, margemFormat, 
    quantidadeServicoFormat, debitoCreditoFormat, dateFormat, porcentagemFormat, localCreditoCartaoFormat, formaRecebimentoAdiantamentoFormat };

