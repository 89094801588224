<script setup>
import { onMounted, inject, defineProps, defineEmits, ref } from "vue";

import Moeda from "../Utils/moeda.vue";
import Data from "../Utils/data.vue";
import Porcentagem from "../Utils/porcentagem.vue";
import { useRoute } from "vue-router";
import { OrdemServicos } from "../../resources/ordemServicosResource";
import { Converte } from "../../utils/converte";


const snackBarApp = inject("snackBarApp");

const props = defineProps({
    propOrdemServicos: {
        type: Object,
    },
    operacao: {
        type: String,
    },
});

const realPorcentagem = ref([
    { text: "Reais", value: "R" },
    { text: "Porcentagem", value: "P" },
]);

const ordemServicos = ref({});
const operacao = ref(props.operacao);
const route = useRoute();
const isReadOnly = ref(false);

const emit = defineEmits(['close-dialog', 'finalizouOrdemServicos']);

onMounted(() => {
    cleanData();

    if(operacao.value == "C") {
       isReadOnly.value = true;
    }
    
    getOrdemServicos(props.propOrdemServicos.idOrdemServicos);

    setTimeout(() => {
        document.getElementById("finalizaDataFinalizacao").focus();
    }, 200);
});


function cleanData() {
    ordemServicos.value.idOrdemServicos = props.propOrdemServicos.idOrdemServicos;
    ordemServicos.value.dataFinalizacao = (new Date().toLocaleDateString("pt-BR"));
    ordemServicos.value.horaFinalizacao = new Date().toLocaleTimeString("pt-BR").slice(0, 5);
    ordemServicos.value.valorTotalPS = 'R$ 0,00';
    ordemServicos.value.valorTotalP = 'R$ 0,00';
    ordemServicos.value.descRPP = 'R';
    ordemServicos.value.descontoP = 'R$ 0,00';
    ordemServicos.value.valorTotalPComDesconto = 'R$ 0,00';
    ordemServicos.value.valorTotalS = 'R$ 0,00';
    ordemServicos.value.descRPS = 'R';
    ordemServicos.value.descontoS = 'R$ 0,00';
    ordemServicos.value.valorTotalSComDesconto = 'R$ 0,00';
    ordemServicos.value.adiantamento = 'R$ 0,00';
    ordemServicos.value.valorTotalAPagar = 'R$ 0,00';
    
    getTotais();

}

async function getTotais() {
    try {
        let response = await OrdemServicos.totais(ordemServicos.value.idOrdemServicos);
        if (response.status == 200) {
            if (response.status == 200) {
                ordemServicos.value.valorTotalPS =  Converte.numberToCurrency(response.data.data.total);
                ordemServicos.value.valorTotalP = Converte.numberToCurrency(response.data.data.totalProdutos);
                ordemServicos.value.valorTotalS = Converte.numberToCurrency(response.data.data.totalServicos);
                ordemServicos.value.adiantamento = Converte.numberToCurrency(response.data.data.adiantamento);
                ordemServicos.value.valorTotalPComDesconto = Converte.numberToCurrency(response.data.data.totalProdutos);
                ordemServicos.value.valorTotalSComDesconto = Converte.numberToCurrency(response.data.data.totalServicos);
                ordemServicos.value.valorTotalAPagar = Converte.numberToCurrency(response.data.data.totalAdiantamento);
                calculaValorAPagar();
            }

        }
    }
    catch (err) {
        snackBarApp.value.open('Erro ao buscar os totais da ordem de serviços.');
        console.log(err);
    }
}


async function submit() {
    let valorTotalPComDesconto = Converte.stringToFloat(ordemServicos.value.valorTotalPComDesconto);
    if(valorTotalPComDesconto < 0 ) {
        snackBarApp.value.open('O valor total dos produtos com desconto deve ser maior ou igual a 0. Corrija os valores dos descontos dos produtos.', 6000);
        return;
    }

    let valorTotalSComDesconto = Converte.stringToFloat(ordemServicos.value.valorTotalSComDesconto);
    if(valorTotalSComDesconto < 0 ) {
        snackBarApp.value.open('O valor total dos serviços com desconto deve ser maior ou igual a 0. Corrija os valores dos descontos dos serviços.', 6000);
        return;
    }

    let valorTotalAPagar = Converte.stringToFloat(ordemServicos.value.valorTotalAPagar);
    if(valorTotalAPagar < 0 ) {
        snackBarApp.value.open('O valor total a pagar deve ser maior ou igual a 0. Corrija os valores dos descontos.', 6000);
        return;
    }

    if(ordemServicos.value.dataFinalizacao == '' || ordemServicos.value.dataFinalizacao == null) {
        snackBarApp.value.open('A data da finalização é obrigatória.', 6000);
        return;
    }

    let obj =  {};
    obj.idOrdemServicos = ordemServicos.value.idOrdemServicos;
    obj.dataFinalizacao = Converte.datePtBrToIso(ordemServicos.value.dataFinalizacao);
    obj.horaFinalizacao = ordemServicos.value.horaFinalizacao;
    obj.descRPP = ordemServicos.value.descRPP;
    obj.descRPS = ordemServicos.value.descRPS;
    obj.descontoP = Converte.stringToFloat(ordemServicos.value.descontoP);
    obj.descontoS = Converte.stringToFloat(ordemServicos.value.descontoS);
    obj.valorTotalAPagar = Converte.stringToFloat(ordemServicos.value.valorTotalAPagar);
    obj.valorTotalPComDesconto = Converte.stringToFloat(ordemServicos.value.valorTotalPComDesconto);
    obj.valorTotalSComDesconto = Converte.stringToFloat(ordemServicos.value.valorTotalSComDesconto);

    let dados = new FormData();
    dados.append('dados', JSON.stringify(obj));

    let response = await  OrdemServicos.finalizaAVista(dados);
    try {
        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open('Não foi possível finalizar a ordem de serviços a vista, '+response.data.message, 6000);
                return;
            }

            snackBarApp.value.open('Ordem de serviços finalizada com sucesso.');
            emit('finalizouOrdemServicos', 'CAI');
            emit('close-dialog');
        }
    }
    catch (err) {
        snackBarApp.value.open('Erro ao finalizar a ordem de serviços a vista.');
    }
}

function changeDescRPP() {
    if(ordemServicos.value.descRPP == 'P') {
        ordemServicos.value.descontoP = Converte.numberToPorcentagem(Converte.stringToFloat(ordemServicos.value.descontoP),2);
    }
    if(ordemServicos.value.descRPP == 'R') {
        ordemServicos.value.descontoP = Converte.numberToCurrency(Converte.stringToFloat(ordemServicos.value.descontoP));
    }

    calculaValorAPagar();
}

function changeDescRPS() {
    if(ordemServicos.value.descRPS == 'P') {
        ordemServicos.value.descontoS = Converte.numberToPorcentagem(Converte.stringToFloat(ordemServicos.value.descontoS),2);
    }
    if(ordemServicos.value.descRPS == 'R') {
        ordemServicos.value.descontoS = Converte.numberToCurrency(Converte.stringToFloat(ordemServicos.value.descontoS));
    }

    calculaValorAPagar();
}


function calculaValorAPagar() {
    let valorAPagar = 0;
    let descontoP = Converte.stringToFloat(ordemServicos.value.descontoP);
    let descontoS = Converte.stringToFloat(ordemServicos.value.descontoS);
    let descRPP = ordemServicos.value.descRPP;
    let descRPS = ordemServicos.value.descRPS;
    let valorTotalP = Converte.stringToFloat(ordemServicos.value.valorTotalP);
    let valorTotalS = Converte.stringToFloat(ordemServicos.value.valorTotalS);
    let adiantamento = Converte.stringToFloat(ordemServicos.value.adiantamento);
    let valorTotalPComDesconto = 0;
    let valorTotalSComDesconto = 0;

    if(descRPP == 'R') {
        valorTotalPComDesconto = valorTotalP - descontoP;
    } else {
        valorTotalPComDesconto = valorTotalP - (valorTotalP * descontoP / 100);
    }

    if(descRPS == 'R') {
        valorTotalSComDesconto = valorTotalS - descontoS;
    } else {
        valorTotalSComDesconto = valorTotalS - (valorTotalS * descontoS / 100);
    }

    valorAPagar = valorTotalPComDesconto + valorTotalSComDesconto - adiantamento;

    ordemServicos.value.valorTotalAPagar = Converte.numberToCurrency(valorAPagar);
    ordemServicos.value.valorTotalPComDesconto = Converte.numberToCurrency(valorTotalPComDesconto);
    ordemServicos.value.valorTotalSComDesconto = Converte.numberToCurrency(valorTotalSComDesconto);
}

async function getOrdemServicos(idOrdemServicos) {
    let dados = new FormData();
    dados.append('codigoModulo', '03.03.03');
    dados.append('idOrdemServicos', idOrdemServicos);

    try {

        let response = await OrdemServicos.getById(dados);
        if (response.status == 200) {

            if (route.name == 'OrdensServicos.Show' || response.data.data.formaPagamento !== null) {
                ordemServicos.value.dataFinalizacao = Converte.dateIsoToPtBr(response.data.data.dataFinalizacao);
                ordemServicos.value.horaFinalizacao = response.data.data.horaFinalizacao;
            }
            ordemServicos.value.descRPP = response.data.data.descRPP;
            ordemServicos.value.descRPS = response.data.data.descRPS;

            if(ordemServicos.value.descRPP !== 'P') {
                ordemServicos.value.descRPP = 'R';
            }

            if(ordemServicos.value.descRPS !== 'P') {
                ordemServicos.value.descRPS = 'R';
            }

            if(ordemServicos.value.descRPP == 'R') {
                ordemServicos.value.descontoP = Converte.numberToCurrency(response.data.data.descontoP);
            }
            else {
                ordemServicos.value.descontoP = Converte.numberToPorcentagem(response.data.data.descontoP,2);
            }

            if(ordemServicos.value.descRPS == 'R') {
                ordemServicos.value.descontoS = Converte.numberToCurrency(response.data.data.descontoS);
            }
            else {
                ordemServicos.value.descontoS = Converte.numberToPorcentagem(response.data.data.descontoS,2);
            }

            calculaValorAPagar();
        }
    }
    catch (err) {
        snackBarApp.value.open('Erro ao buscar a ordem de serviços.');
        console.log(err);
    }
}
</script>

<template>
    <v-card elevation="16">
        <v-card-title>
            <h2 v-show="operacao !== 'C'">Finalização da Ordem de Serviços a Vista</h2>
            <h2 v-show="operacao == 'C'">Ordem de Serviços Finalizada a Vista</h2>
        </v-card-title>
        <form ref="formFinalizaOrdemServicosAVista" @submit.prevent="submit">
            <v-card-text>
                <v-row>
                    <v-col cols="2">
                        <Data label="Data da Finalização" id="finalizaDataFinalizacao" v-model="ordemServicos.dataFinalizacao" :readonly="isReadOnly"/>
                    </v-col>    
                    <v-col cols="2">
                        <v-text-field type="time" label="Hora da Finalização" id="finalizaHoraFinalizacao" v-model="ordemServicos.horaFinalizacao" :readonly="isReadOnly"/>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="Valor Total Produtos+Serviços" id="valorTotalPS" v-model="ordemServicos.valorTotalPS" readOnly tabindex="-1" />
                    </v-col>
                    <v-col cols="2">
                        <v-text-field label="Valor Total Produtos" id="valorTotalP" v-model="ordemServicos.valorTotalP" readOnly tabindex="-1" />
                    </v-col>
                    <v-col cols="3">
                        <v-select
                                :items="realPorcentagem"
                                item-title="text"
                                item-value="value"
                                label="Desc. em R$ ou em % Produtos"
                                v-model="ordemServicos.descRPP"
                                :readonly="isReadOnly"
                                @update:modelValue="changeDescRPP()"
                         ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3" v-show="ordemServicos.descRPP == 'R'">
                        <Moeda label="Desconto nos Produtos em (R$)" id="descontoP" v-model="ordemServicos.descontoP" 
                            @blur="calculaValorAPagar()" 
                            :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3" v-show="ordemServicos.descRPP == 'P'">
                        <Porcentagem label="Desconto nos Produtos em (%)" id="descontoP" v-model="ordemServicos.descontoP" 
                            @blur="calculaValorAPagar()" 
                            :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3">
                        <Moeda label="V.Total dos Produtos com Descontos" id="valorTotalPComDesconto" 
                            v-model="ordemServicos.valorTotalPComDesconto" 
                            readonly tabindex="-1" />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="Valor Total Serviços" id="valorTotalS" v-model="ordemServicos.valorTotalS" readOnly tabindex="-1" />
                    </v-col>
                    <v-col cols="3">
                        <v-select
                                :items="realPorcentagem"
                                item-title="text"
                                item-value="value"
                                label="Desc.em R$ ou em % Serviços"
                                v-model="ordemServicos.descRPS"
                                :readonly="isReadOnly"
                                @update:modelValue="changeDescRPS()"
                            ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3" v-show="ordemServicos.descRPS == 'R'">
                        <Moeda label="Desconto nos Serviços em (R$)" id="descontoS" v-model="ordemServicos.descontoS" 
                            @blur="calculaValorAPagar()" 
                            :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3" v-show="ordemServicos.descRPS == 'P'">
                        <Porcentagem label="Desconto nos Serviços em (%)" id="descontoS" v-model="ordemServicos.descontoS" 
                            @blur="calculaValorAPagar()" 
                            :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3">
                        <Moeda label="Valor Total dos Serviços com Descontos" id="valorTotalSComDesconto" 
                            v-model="ordemServicos.valorTotalSComDesconto" 
                            readonly tabindex="-1" />
                    </v-col>
                    <v-col cols="3">
                        <Moeda label="Adiantamento" id="adiantamento" v-model="ordemServicos.adiantamento" 
                            readOnly 
                            tabindex="-1"/>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="Valor Total a Pagar" 
                            id="valorTotalAPagar" 
                            v-model="ordemServicos.valorTotalAPagar" 
                            readOnly 
                            tabindex="-1" />
                    </v-col>
                </v-row>

            </v-card-text>
            <v-card-actions>
                <v-btn v-show="operacao !== 'C'" color="primary" variant="elevated" @click.prevent="submit()" id="btnConfirmarAVista">Confirmar</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    variant="elevated"
                    @click.prevent="emit('close-dialog')">
                    Fechar
                    </v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>