<script setup>
import { ref, onMounted, inject, onUnmounted } from "vue";
import { ContaReceber } from "@/resources/contaReceberResource";
import { ReciboCliente } from "@/resources/reciboClienteResource";
import { Cliente } from "@/resources/clienteResource";
import { Conta } from "../../resources/contaResource";
import { Cartao } from "../../resources/cartaoResource";
import config from "../../services/config";
import MsgConfirm from "../Utils/MsgConfirm";
import { valorFormat } from "../../services/filtros";
import ClienteBusca from "../Clientes/ClienteBusca.vue";
import Data from "../Utils/data.vue";
import Moeda from "../Utils/moeda.vue";
import { Converte } from "../../utils/converte";
import { Relatorio } from '../../resources/relatoriosResource';

let msgConfirm = ref(null);
let snackBarApp = inject("snackBarApp");

let dialogCliente = ref(false);

let lancamentosContasReceber = ref([]);

let idCliente = ref(null);
let codigoCliente = ref('');
let nomeCliente = ref('');
let documento = ref('');
let desconto = ref('R$ 0,00');
let acrescimo = ref('R$ 0,00');
let valorTotalAReceber = ref('R$ 0,00');
let valorTotalAReceberDescAcres = ref('R$ 0,00');
let valorRecebido = ref('R$ 0,00');
let dataPagamento = ref(new Date().toLocaleDateString("pt-BR"));
let formaPagamento = ref('CON');
let idConta = ref(null);
let detalhesConta = ref('');
let idCartao = ref(null);
let parcelasCartao = ref(1);

let digitouValor = ref(true);

let auxValorRecebidoMudou = 0;
let auxDataPagamentoMudou = new Date().toLocaleDateString("pt-BR");

const cartoes = ref([]);
const contas = ref([]);

const itensFormaPagamento = ref([
    { value: 'CON', label: 'PIX/Transferência' },
    { value: 'DIN', label: 'Dinheiro' },
    { value: 'CAR', label: 'Cartão/Convênio' }
]);

onMounted(() => {
    getContas();
    getCartoes();
});

function cleanData() {
    lancamentosContasReceber.value = [];

    idCliente.value = null;
    codigoCliente.value = '';
    nomeCliente.value = '';
    documento.value = '';
    desconto.value = 'R$ 0,00';
    acrescimo.value = 'R$ 0,00';
    valorTotalAReceber.value = 'R$ 0,00';
    valorTotalAReceberDescAcres.value = 'R$ 0,00';
    valorRecebido.value = 'R$ 0,00';
    dataPagamento.value = new Date().toLocaleDateString("pt-BR");
    formaPagamento.value = 'CON';
    parcelasCartao.value = 1;

    digitouValor.value = true;

    auxValorRecebidoMudou = 0;
    auxDataPagamentoMudou = new Date().toLocaleDateString("pt-BR");

    document.getElementById("codigoCliente").focus();

}

async function filtrar() {
    if(idCliente.value == null || idCliente.value == "" || idCliente.value == undefined) {
        snackBarApp.value.open("Cliente não escolhido, escolha um cliente.", 6000);
        document.getElementById("codigoCliente").focus();
        return;
    }

    if(dataPagamento.value == null || dataPagamento.value == "" || dataPagamento.value == undefined) {
        snackBarApp.value.open("Data do recebimento não informada.", 6000);
        document.getElementById("dataPagamento").focus();
        return;
    }

    let auxDataPagamento = Converte.datePtBrToIso(dataPagamento.value);

    desconto.value = 'R$ 0,00';
    valorRecebido.value = 'R$ 0,00';
    acrescimo.value = 'R$ 0,00';

    try {
        const response = await ContaReceber.listarQuitarVarios(idCliente.value, documento.value, auxDataPagamento);
        lancamentosContasReceber.value = response.data.data;

        const valorTotal = response.data.totalAReceber + Converte.stringToFloat(acrescimo.value) - Converte.stringToFloat(desconto.value);
        valorTotalAReceber.value = Converte.numberToCurrencyDuasCasas(valorTotal);

        calculaValorAReceber();
    } catch (err) {
        console.log(err);
    }
}

function selecionouCliente(cliente) {
    if (cliente == null) {
        return;
    }

    codigoCliente.value = cliente.codigo;
    getCliente();
    dialogCliente.value = false;
}


async function getCliente() {
    idCliente.value = null;
    nomeCliente.value = "";

    if (codigoCliente.value.toString().trim() == "") {
        return;
    }

    let dados = new FormData();
    dados.append("codigo", codigoCliente.value);
    dados.append("getInativos", true);
    dados.append("codigoModulo", "03.02");

    try {
        let response = await Cliente.getByCodigo(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                document.getElementById("codigoCliente").focus();
                snackBarApp.value.open("O cliente não foi encontrado, tente outro código ou faça uma busca.");
                codigoCliente.value = "";
                return;
            }
            idCliente.value = response.data.data.idCliente;
            codigoCliente.value = response.data.data.codigo;
            nomeCliente.value = response.data.data.nome;
        }
    } catch (err) {
        console.log("Erro ao buscar cliente: " + err);
    }
}


document.addEventListener('keydown', handleKeydown);

function handleKeydown(event) {
    if(event.key === 'F5') {
        event.preventDefault();
    }

    if (event.key === 'F5' && document.activeElement.id === 'codigoCliente' ) {
        event.preventDefault();
        document.getElementById('btnBuscaCliente').click();
    }
}

onUnmounted(() => {
    document.removeEventListener('keydown', handleKeydown);
});

async function quitarVarios() {
    let lancamentos = [];

    if(dataPagamento.value == null || dataPagamento.value == "" || dataPagamento.value == undefined) {
        snackBarApp.value.open("Data do recebimento não informada.", 6000);
        document.getElementById("dataPagamento").focus();
        return;
    }

    let auxDataPagamento = Converte.datePtBrToIso(dataPagamento.value);
    let descricao = '';   // Descrição do recibo de clientes;

    lancamentosContasReceber.value.forEach((o) => {
        if (o.selecionado) {
            lancamentos.push(o);
            descricao += descricao+"lançamento:"+o.lancamento+", histórico:"+o.historico+", ";
        }
    });

    let obj = {};
    obj.lancamentos = lancamentos;
    obj.valorRecebido = Converte.stringToFloat(valorRecebido.value);
    obj.desconto = Converte.stringToFloat(desconto.value);
    obj.acrescimo = Converte.stringToFloat(acrescimo.value);
    obj.dataPagamento = auxDataPagamento;
    obj.formaPagamento = formaPagamento.value;
    obj.idCliente = idCliente.value;
    obj.idCartao = idCartao.value;
    obj.parcelasCartao = parcelasCartao.value;
    obj.idConta = idConta.value;

    let dados = new FormData();
    dados.append('data', JSON.stringify(obj));
    let response = await ContaReceber.quitarVarios(dados);
    if(response.status == 200) {
        if (response.data.success == false) {
            snackBarApp.value.open("Erro ao receber, mensagem de erro: " + response.data.message);
            return;
        }

        snackBarApp.value.open("Recebimento efetuado com sucesso.");
        let valor = obj.valorRecebido;

        openModalGerarRecibo(auxDataPagamento, valor, idCliente, descricao);

        cleanData();

    }
    else {
        snackBarApp.value.open("Erro ao receber, mensagem de erro: " + response.data.message);
    }
}

function openModalGerarRecibo(data, valor, idCliente, descricao) {
    msgConfirm.value
        .open(
            "Gerar Recibo ?",
            "Deseja gerar um recibo para o cliente ?",
            { color: "primary" }
        )
        .then((confirm) => {
            if (confirm) {
                gerarRecibo(data, valor, idCliente, descricao);
            }
            else {
                // router.push({'name':'ContasReceber.List'});
            }
            
        });
}

async function gerarRecibo(data, valor, idCliente, descricao) {
    let obj =  {};
    obj.codigo = null;
    obj.idModulo = null;
    obj.modulo = 'CRECE';
    obj.data =  data;
    obj.valor = valor;
    obj.idCliente = idCliente;
    obj.descricao = descricao;
    
    let dados = new FormData();
    dados.append('data', JSON.stringify(obj));

    try {
        const response = await ReciboCliente.save(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open("O recibo não pode ser gerado, mensagem de erro: " + response.data.message, 6000);
                return;
            }
            const idReciboCliente = response.data.data.idReciboCliente;
            let nomeRel = 'Recibo Cliente';

            let responseRel = await Relatorio.get(nomeRel, idReciboCliente);
            if (responseRel.status == 200) {
                if (responseRel.data.success == true) {
                    let nomeArquivo = responseRel.data.nomeArquivo;
                    let url = config.baseUrlRel + nomeArquivo;

                    window.open(url, '_blank');
                    return;

                }
                if (response.data.success == false) {
                    snackBarApp.value.open(responseRel.data.message);
                    return;
                }

                snackBarApp.value.open("Não foi possível fazer a impressão do recibo, tente novamente.");
            }

        }
    } catch (err) {
        snackBarApp.value.open("Erro, mensagem de erro: " + err);
    }
}

function calculaValorAReceber() {
    let valorTotal = Converte.stringToFloat(valorTotalAReceber.value);

    let valorAReceber = valorTotal - Converte.stringToFloat(desconto.value) + Converte.stringToFloat(acrescimo.value);
    valorTotalAReceberDescAcres.value = Converte.numberToCurrencyDuasCasas(valorAReceber);

    let totalRecebido = 0;
    lancamentosContasReceber.value.forEach(lancamento => {
        if(lancamento.selecionado) 
        {
            totalRecebido += parseFloat(lancamento.valorAReceber);
        }
    });

    totalRecebido = totalRecebido - Converte.stringToFloat(desconto.value) + Converte.stringToFloat(acrescimo.value);

    valorRecebido.value = Converte.numberToCurrencyDuasCasas(totalRecebido);

    auxValorRecebidoMudou = valorRecebido.value;
}   

function calculaValorSelecionados() {
    if(digitouValor.value == true) {
        return;
    }
    let auxValorRecebido = 0;

    lancamentosContasReceber.value.forEach(lancamento => {
        if(lancamento.selecionado) {
            auxValorRecebido += parseFloat(lancamento.valorAReceber);
        }
    });

    auxValorRecebido = auxValorRecebido - Converte.stringToFloat(desconto.value) + Converte.stringToFloat(acrescimo.value);

    valorRecebido.value = Converte.numberToCurrencyDuasCasas(auxValorRecebido);
    auxValorRecebidoMudou = valorRecebido.value;
}

function selecionaParcelas() {
    if(Converte.stringToFloat(auxValorRecebidoMudou) == Converte.stringToFloat(valorRecebido.value)) {
        return true;
    }
    else {
        digitouValor.value = true;
    }

    if(digitouValor.value == false ) {
        return;
    }

    auxValorRecebidoMudou = valorRecebido.value;

    if(Converte.stringToFloat(valorRecebido.value) > Converte.stringToFloat(valorTotalAReceberDescAcres.value)) {
        snackBarApp.value.open("O valor recebido não pode ser maior que o valor total a receber com descontos e acréscimos.", 6000);
        valorRecebido.value = valorTotalAReceberDescAcres.value;
        return;
    }

    lancamentosContasReceber.value.forEach(lancamento => {
        if(lancamento.selecionado) {
            lancamento.selecionado = false;
        }
    });

    let valorRecebidoRestante = Converte.stringToFloat(valorRecebido.value) + Converte.stringToFloat(desconto.value) - Converte.stringToFloat(acrescimo.value);

    lancamentosContasReceber.value.forEach(lancamento => {
        if(valorRecebidoRestante > 0) {
            lancamento.selecionado = true;
            valorRecebidoRestante -= parseFloat(lancamento.valorAReceber);
        }
    });
}

async function getContas() {
    const response = await Conta.getContas();
    contas.value = response.data.data;

    if (contas.value.length > 0 && (idConta.value == null || idConta.value <= 0)) {
        idConta.value = response.data.data[0].idConta;
    }

    changeConta();
}

async function changeConta() {
    let dados = new FormData();
    dados.append('codigoModulo', '03.02');
    dados.append('idConta', idConta.value);
    try {
        const response = await Conta.getById(dados);
        if (response.status == 200) {
            let conta = response.data.data;

            detalhesConta.value = 'Banco:'+conta.nomeBanco+' agência:'+conta.agencia+' nro.conta:'+conta.conta+' saldo atual:'+Converte.numberToCurrency(conta.saldo);

            if(conta == []) {
                detalhesConta.value = "";
            }
        }
    }
    catch (err) {
        console.log(err);
    }
}

async function getCartoes() {
    try {
       
        let dados = new FormData();
        dados.append('soAtivos', true);

        let response = await Cartao.getCartoes(dados);
        cartoes.value = response.data.data;
        if (idCartao.value == null && cartoes.value.length > 0) {
            idCartao.value = response.data.data[0].idCartao;
            return;
        }
    }
    catch (err) {
        snackBarApp.value.open('Erro ao buscar os cartões.');
        console.log(err);
    }
}

function recalcular(){
    if((auxDataPagamentoMudou != dataPagamento.value) && lancamentosContasReceber.value.length > 0) {
        auxDataPagamentoMudou = dataPagamento.value;
        filtrar();
        valorRecebido.value = 'R$ 0,00';
        
        lancamentosContasReceber.value.forEach(lancamento => {
            lancamento.selecionado = false;
        });

        snackBarApp.value.open('A data do recebimento foi alterada, os valores de foram recalculados.');
    }
}


</script>

<template>
    <MsgConfirm ref="msgConfirm"></MsgConfirm>

    <v-row>
        <v-col>
            <h1>Quita Vários Lançamentos de Contas a Receber</h1>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <v-card elevation="16">
                <v-card-title>Cliente</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="3">
                            <v-text-field id="codigoCliente" label="Cód.Cliente" type="text" 
                                v-model="codigoCliente" @blur="getCliente()"
                                v-tooltip="'F5-Abre a busca de clientes.'"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Nome do Cliente" type="text" v-model="nomeCliente" readonly tabindex="-1">
                                <template v-slot:append>
                                    <v-tooltip text="Faz a busca de um cliente por nome/cpf.">
                                        <template v-slot:activator="{ props }">
                                            <v-btn v-bind="props" color="primary" @click="dialogCliente = true" id="btnBuscaCliente">
                                                <v-icon>mdi-magnify</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-tooltip>
                                    <v-dialog v-model="dialogCliente" transition="dialog-top-transition">
                                        <ClienteBusca 
                                            @selecionouCliente="selecionouCliente" 
                                            @close-dialog="dialogCliente = false" 
                                            :select-inativo-bloqueado=true />
                                    </v-dialog>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field label="Documento" type="text" v-model="documento">
                                <template v-slot:append>
                                    <v-btn color="primary" @click.prevent="filtrar()" prepend-icon="mdi-magnify">Filtrar</v-btn>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <v-card elevation="16">
                <v-card-title>Dados do Recebimento</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="3">
                            <Moeda
                                label="V.Total A Receber(R$)"
                                id="valorTotalAReceber"
                                type="text"
                                v-model="valorTotalAReceber"
                                readOnly
                                tabindex="-1"
                            />
                        </v-col>

                        <v-col cols="3">
                            <Moeda
                                label="Descontos(R$)"
                                id="desconto"
                                type="text"
                                v-model="desconto"
                                @blur="calculaValorAReceber();selecionaParcelas()"
                            />
                        </v-col>
                        <v-col cols="3">
                            <Moeda
                                label="Acréscimos(R$)"
                                id="acrescimo"
                                type="text"
                                v-model="acrescimo"
                                @blur="calculaValorAReceber();selecionaParcelas()"
                            />
                        </v-col>
                        <v-col cols="3">
                            <Moeda
                                label="V.Total A Receber Com Desc.e Acréscimo(R$)"
                                id="valorTotalAReceberDescAcres"
                                type="text"
                                v-model="valorTotalAReceberDescAcres"
                                readOnly
                                tabindex="-1"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <Data label="Data Recebimento" type="text" v-model="dataPagamento"  @blur="recalcular()"></Data>
                        </v-col>
                        <v-col cols="3">
                            <Moeda
                                label="Valor que Será Recebido"
                                id="valorRecebido"
                                type="text"
                                v-model="valorRecebido"
                                @blur="selecionaParcelas()"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-select
                                :items="itensFormaPagamento"
                                label="Forma Recebimento"
                                v-model="formaPagamento"
                                item-title="label"
                                item-value="value"
                                id="cbFormaPagamento"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3" v-show="formaPagamento == 'CON'">
                            <v-select v-model="idConta"  :items="contas" 
                                item-title="apelido" item-value="idConta" @update:modelValue="changeConta()" label="Conta" 
                                no-data-text="Nenhuma Conta Bancária"></v-select>
                        </v-col>    
                        <v-col cols="9" v-show="formaPagamento == 'CON'">
                            <v-text-field label="Detalhes da Conta" v-model="detalhesConta" readonly tabindex=-1></v-text-field>
                        </v-col>    
                        <v-col cols="8" v-show="formaPagamento == 'CAR'">
                            <v-select v-model="idCartao"  :items="cartoes" 
                                item-title="nome" item-value="idCartao" label="Cartão/Convênio" 
                                no-data-text="Nenhum Cartão/Convênio Cadastrado"></v-select>
                        </v-col>
                        <v-col cols="4" v-show="formaPagamento == 'CAR'">
                            <v-text-field
                                label="Número de Parcelas do Cartão/Convênio"
                                id="parcelasCartao"
                                type="text"
                                v-model="parcelasCartao"
                                maxlength="2"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <p></p>
    <v-card elevation="16" class="mt-4">
        <v-card-text>
            <v-table density="compact">
                <thead>
                    <tr>
                        <th></th>
                        <th>Lançamento</th>
                        <th>D.Vencimento</th>
                        <th>Valor</th>
                        <th>Valor Recebido</th>
                        <th>Valor a Receber</th>
                        <th>Documento</th>
                        <th>Histórico</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="o in lancamentosContasReceber" :key="o">
                        <td><v-checkbox v-model="o.selecionado" @click="digitouValor=false" @change="calculaValorSelecionados"></v-checkbox></td>
                        <td>{{ o.lancamento }}</td>
                        <td>{{ o.dataVencimento }}</td>
                        <td>{{ valorFormat(o.valor) }}</td>
                        <td>{{ valorFormat(o.valorRecebido) }}</td>
                        <td>{{ valorFormat(o.valorAReceber)}}</td>
                        <td>{{ o.documento }}</td>
                        <td>{{ o.historico }}</td>
                    </tr>
                </tbody>
            </v-table>
        </v-card-text>
        <v-card-actions>
            <v-btn color="primary" variant="elevated" v-show="lancamentosContasReceber.length > 0 && Converte.stringToFloat(valorRecebido) > 0" @click.prevent="quitarVarios()" id="btnReceberVariasReceber">Receber</v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                variant="elevated"
                @click.prevent="$router.go(-1)">
                Voltar
            </v-btn>
        </v-card-actions>

    </v-card>
    <p></p>
  </template>