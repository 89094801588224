import Http from './http';

export class ContaReceber {
    static listar = function(start, limit, sort, filtrarPor, dataInicial, dataFinal, abertos, cancelados, quitados, idCliente, filtro, tipoFiltro) {
        return Http.get('/Serv/ContasReceber/ContasReceber.php', {
            params: {
                'operacao': 'listar',
                'start':start,
                'limit':limit,
                'sort':sort,
                'filtrarPor': filtrarPor,
                'dataInicial': dataInicial,
                'dataFinal': dataFinal,
                'abertos': abertos,
                'cancelados': cancelados,
                'quitados': quitados,
                'idCliente': idCliente,
                'filtro': filtro,
                'tipoFiltro': tipoFiltro,
            }
        });
    };

    static listarQuitarVarios = function(idCliente, documento, dataRecebimento) {
        return Http.get('/Serv/ContasReceber/ContasReceber.php', {
            params: {
                'operacao': 'listarquitarvarios',
                'idCliente': idCliente,
                'documento': documento,
                'dataRecebimento': dataRecebimento
            }
        });
    };

    static novoLancamento = function(dados) {
        return Http.post('/Serv/ContasReceber/ContasReceber.php?operacao=novolancamento', dados);
    };

    static proximoLancamento = function() {
        return Http.get('/Serv/ContasReceber/ContasReceber.php?operacao=proximolancamento');
    };

    static lancamentoExiste = function(lancamento, idContaReceber) {
        let dados = new FormData();
        dados.append('lancamento', lancamento);
        dados.append('idContaReceber', idContaReceber);
        return Http.post('/Serv/ContasReceber/ContasReceber.php?operacao=lancamentoexiste', dados);
    };

    static gerarParcelas = function(dados) {
        return Http.post('/Serv/ContasReceber/ContasReceber.php?operacao=gerarparcelas', dados);
    };

    static novosLancamentos = function(dados) {
        return Http.post('/Serv/ContasReceber/ContasReceber.php?operacao=novoslancamentos', dados);
    };

    static getById = function(dados) {
        return Http.post('/Serv/ContasReceber/ContasReceber.php?operacao=getbyid', dados);
    };

    static alteraLancamento = function(dados) {
        return Http.post('/Serv/ContasReceber/ContasReceber.php?operacao=alteralancamento', dados);
    };

    static quitaLancamento = function(dados) {
        return Http.post('/Serv/ContasReceber/ContasReceber.php?operacao=quitalancamento', dados);
    };

    static getRecebimentosById = function(dados) {
        return Http.post('/Serv/ContasReceber/ContasReceber.php?operacao=getrecebimentosbyid', dados);
    };

    static excluir = function(dados) {
        return Http.post('/Serv/ContasReceber/ContasReceber.php?operacao=excluir', dados);
    };

    static cancelar = function(dados) {
        return Http.post('/Serv/ContasReceber/ContasReceber.php?operacao=cancelar', dados);
    };

    static excluirRecebimento = function(dados) {
        return Http.post('/Serv/ContasReceber/ContasReceber.php?operacao=excluirrecebimento', dados);
    };

    static quitarVarios = function(dados) {
        return Http.post('/Serv/ContasReceber/ContasReceber.php?operacao=quitarvarios', dados);
    };



}
