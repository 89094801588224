<script setup>
import { ref, computed, onMounted, inject, onUnmounted } from "vue";
import { OrdemServicos } from "@/resources/ordemServicosResource";
import { Relatorio } from "@/resources/relatoriosResource";
import { Cliente } from "@/resources/clienteResource";
import config from "../../services/config";
import { PermissaoService } from "../../services/permissaoService";
import MsgConfirm from "../Utils/MsgConfirm";
import { useOrdensServicos } from "../../store/ordensServicos";
import { useConfiguracoesOrdensServicos } from "../../store/configuracoesOrdensServicos";
import { valorFormat } from "../../services/filtros";
import ClienteBusca from "../Clientes/ClienteBusca.vue";
import AlterarCodigoOrdemServicos from "./AlterarCodigoOrdemServicos.vue";
import Data from "../Utils/data.vue";
import { Converte } from "../../utils/converte";

let msgConfirm = ref(null);
let snackBarApp = inject("snackBarApp");

let dialogCliente = ref(false);
let dialogAlterarCodigoOrdemServicos = ref(false);

const ordensServicosStore = useOrdensServicos();
const configuracoesOrdensServicosStore = useConfiguracoesOrdensServicos();
let ordensServicos = ref({});
const total = ref(0);
const pageSize = ref(config.maxPerPage);
const currentPage = ref(ordensServicosStore.currentPage);
const abertas = ref(ordensServicosStore.abertas);
const canceladas = ref(ordensServicosStore.canceladas);
const finalizadas = ref(ordensServicosStore.finalizadas);
const dataInicial = ref(ordensServicosStore.dataInicial);
const dataFinal = ref(ordensServicosStore.dataFinal);
const idCliente = ref(ordensServicosStore.idCliente);
const codigoCliente = ref(ordensServicosStore.codigoCliente);
const nomeCliente = ref(ordensServicosStore.nomeCliente);
const filtrarPor = ref(ordensServicosStore.filtrarPor);
const codigo = ref(ordensServicosStore.codigo);
const campo = ref(ordensServicosStore.campo);
const buscaCampo = ref(ordensServicosStore.buscaCampo);
const labelCampo1 = ref(configuracoesOrdensServicosStore.labelCampo1);
const labelCampo2 = ref(configuracoesOrdensServicosStore.labelCampo2);
const labelCampo3 = ref(configuracoesOrdensServicosStore.labelCampo3);
const labelCampo4 = ref(configuracoesOrdensServicosStore.labelCampo4);
const labelCampo5 = ref(configuracoesOrdensServicosStore.labelCampo5);
const jaCarregado = ref(configuracoesOrdensServicosStore.jaCarregado);

const auxIdOrdemServicos = ref(null);

const itemsFiltrarPor = ref([
    { value: 'data', label: 'Data' },
    { value: 'prazo', label: 'Prazo de Entrega' },
    { value: 'dataFinalizacao', label: 'Data da Finalização' },
]);

const labelCampos = ref([
    { value: 'campo1', label: labelCampo1.value },
    { value: 'campo2', label: labelCampo2.value },
    { value: 'campo3', label: labelCampo3.value },
    { value: 'campo4', label: labelCampo4.value },
    { value: 'campo5', label: labelCampo5.value },
]);

const direction = computed(() => {
    return ordensServicosStore.sort[0].direction;
});

const orderBy = computed(() => {
    return ordensServicosStore.sort[0].property;
});

onMounted(() => {
    if(jaCarregado.value == false) {
        setTimeout(() => {
            labelCampo1.value = configuracoesOrdensServicosStore.labelCampo1;
            labelCampo2.value = configuracoesOrdensServicosStore.labelCampo2;
            labelCampo3.value = configuracoesOrdensServicosStore.labelCampo3;
            labelCampo4.value = configuracoesOrdensServicosStore.labelCampo4;
            labelCampo5.value = configuracoesOrdensServicosStore.labelCampo5;
            labelCampos.value = [
                { value: 'campo1', label: configuracoesOrdensServicosStore.labelCampo1 },
                { value: 'campo2', label: configuracoesOrdensServicosStore.labelCampo2 },
                { value: 'campo3', label: configuracoesOrdensServicosStore.labelCampo3 },
                { value: 'campo4', label: configuracoesOrdensServicosStore.labelCampo4 },
                { value: 'campo5', label: configuracoesOrdensServicosStore.labelCampo5 },
            ];

        }, 1000);

    }

    filtrar('geral');
});

async function destroy(idOrdemServicos) {
    let dados = new FormData();
    dados.append("idOrdemServicos", JSON.stringify(idOrdemServicos));

    try {
        const response = await OrdemServicos.excluir(dados);
        if (response.status == 200) {
            if (response.data.success == "true") {
                snackBarApp.value.open("Ordem de serviços excluída com sucesso.");
                filtrar();
            }
        }
    } catch (err) {
        console.log(err);
    }
}

function openModalDelete(lancamento) {
    msgConfirm.value
        .open(
            "Confirma Exclusão ?",
            "Confirma a exclusão dessa ordem de serviços ?",
            { color: "primary" }
        )
        .then((confirm) => {
            if (confirm) {
                destroy(lancamento.idOrdemServicos);
            }
        });
}

async function filtrar(tipoFiltro, mudouPagina = false) {
    let start = ordensServicosStore.start;
    let limit = ordensServicosStore.limit;
    let filtro = '';
    let sort = JSON.stringify(ordensServicosStore.sort);

    if(tipoFiltro == null || tipoFiltro == "" || tipoFiltro == undefined) {
        tipoFiltro = ordensServicosStore.tipoFiltro;
    }
    ordensServicosStore.setIdCliente(idCliente.value);
    ordensServicosStore.setCodigo(codigo.value);
    ordensServicosStore.setCodigoCliente(codigoCliente.value);
    ordensServicosStore.setNomeCliente(nomeCliente.value);
    ordensServicosStore.setDataInicial(dataInicial.value);
    ordensServicosStore.setDataFinal(dataFinal.value);
    ordensServicosStore.setAbertas(abertas.value);
    ordensServicosStore.setCanceladas(canceladas.value);
    ordensServicosStore.setFinalizadas(finalizadas.value);
    ordensServicosStore.setTipoFiltro(tipoFiltro);
    ordensServicosStore.setFiltrarPor(filtrarPor.value);
    ordensServicosStore.setCampo(campo.value);
    ordensServicosStore.setBuscaCampo(buscaCampo.value);

    let auxDataInicial = null;
    let auxDataFinal = null;
    if (dataInicial.value != "") {
        auxDataInicial = Converte.datePtBrToIso(dataInicial.value); 
    }

    if (dataFinal.value != "") {
        auxDataFinal = Converte.datePtBrToIso(dataFinal.value); 
    }
    if(tipoFiltro == 'codigo') {
        filtro = codigo.value;
    }

    if(tipoFiltro == 'campo') {
        filtro = buscaCampo.value;
    }

    if(mudouPagina == false) {
        handleCurrentChange(1);
        return;
    }

    try {
        const response = await OrdemServicos.listar(start, limit, sort, filtrarPor.value, auxDataInicial, auxDataFinal, abertas.value, 
            canceladas.value, finalizadas.value, idCliente.value, filtro, tipoFiltro, campo.value);
        ordensServicos.value = response.data.data;
        total.value = parseInt(response.data.total);
    } catch (err) {
        console.log(err);
    }
}
function handleCurrentChange(val) {
    let start = (val - 1) * pageSize.value;
    ordensServicosStore.setCurrentPage(val);
    ordensServicosStore.setStart(start);
    currentPage.value = val;
    filtrar(null, true);
}
function setSort(property) {
    let sort = ordensServicosStore.sort;
    let directionAtual = sort[0].direction;
    let direction = "";

    if (property !== sort[0].property) {
        direction = "ASC";
    } else {
        if (directionAtual == "ASC") {
            direction = "DESC";
        } else {
            direction = "ASC";
        }
    }

    let aSort = [
        {
            property: property,
            direction: direction,
        },
    ];

    ordensServicosStore.setSort(aSort);
    filtrar();
}

function temPermissao(codigoModulo) {
    return PermissaoService.temPermissao(codigoModulo);
}

function selecionouCliente(cliente) {
    if (cliente == null) {
        return;
    }

    codigoCliente.value = cliente.codigo;
    getCliente();
    dialogCliente.value = false;
}

async function getCliente() {
    idCliente.value = null;
    nomeCliente.value = "";
    if (codigoCliente.value.toString().trim() == "") {
        return;
    }

    let dados = new FormData();
    dados.append("codigo", codigoCliente.value);
    dados.append("getInativos", true);
    dados.append("codigoModulo", "03.03");

    try {
        let response = await Cliente.getByCodigo(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                document.getElementById("codigoCliente").focus();
                snackBarApp.value.open("O cliente não foi encontrado, tente outro código ou faça uma busca.");
                codigoCliente.value = "";
                return;
            }
            idCliente.value = response.data.data.idCliente;
            codigoCliente.value = response.data.data.codigo;
            nomeCliente.value = response.data.data.nome;
        }
    } catch (err) {
        console.log("Erro ao buscar o cliente: " + err);
    }
}

function openModalCancelar(lancamento) {
    msgConfirm.value
        .open(
            "Confirma Cancelamento ?",
                "Confirma o cancelamento dessa ordem de serviços ?",
            { color: "primary" }
        )
        .then((confirm) => {
            if (confirm) {
                cancelar(lancamento.idOrdemServicos);
            }
        });
}

async function cancelar(idOrdemServicos) {
    let dados = new FormData();
    dados.append("idOrdemServicos", idOrdemServicos);

    try {
        const response = await OrdemServicos.cancelar(dados);
        if (response.status == 200) {
            if (response.data.success == "true") {
                snackBarApp.value.open("Ordem de serviços cancelada.");
                filtrar();
            } else {
                snackBarApp.value.open("Não foi possível cancelar a ordem de serviços.");
            }
        }
    } catch (err) {
        console.log(err);
    }
}

async function imprimir(idOrdemServicos) {
    let nomeRel = 'OrdemServicos'; 

    let response = await Relatorio.get(nomeRel, idOrdemServicos);
    if (response.status == 200) {
        if(response.data.success == true) {
            let nomeArquivo = response.data.nomeArquivo;
            let url = config.baseUrlRel+nomeArquivo;

            window.open(url, '_blank');
            return;

        }
        if(response.data.success == false ) {
            snackBarApp.value.open(response.data.message);
            return;
        }

        snackBarApp.value.open("Não foi possível fazer a impressão da ordem de serviços, tente novamente.");
    }
}

document.addEventListener('keydown', handleKeydown);

function handleKeydown(event) {
    if(event.key === 'F5') {
        event.preventDefault();
    }

    if (event.key === 'F5' && document.activeElement.id === 'codigoCliente' ) {
        event.preventDefault();
        document.getElementById('btnBuscaCliente').click();
    }
}

onUnmounted(() => {
    document.removeEventListener('keydown', handleKeydown);
});

async function duplicar(idOrdemServicos) {
    let dados = new FormData();
    dados.append("idOrdemServicos", idOrdemServicos);

    let response = await OrdemServicos.duplica(dados);
    if (response.status == 200) {
        if(response.data.success == true) {
            snackBarApp.value.open("Ordem de serviços duplicada com sucesso. O código da nova ordem de serviços é: " + response.data.codigo);
            filtrar();
            return; 
        }
        if(response.data.success == false ) {
            snackBarApp.value.open(response.data.message);
            return;
        }

        snackBarApp.value.open("Não foi possível duplicar a ordem de serviços, tente novamente.");
    }
}

</script>

<template>
    <MsgConfirm ref="msgConfirm"></MsgConfirm>
    <v-row>
        <v-col>
            <h1>Ordens de Serviços</h1>
        </v-col>
    </v-row>

    <v-row>
        <v-col>
            <v-btn v-show="temPermissao('03.03.01')" color="primary" :to="{ name: 'OrdensServicos.Create' }">Nova Ordem de Serviços</v-btn>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="4">
            <v-card elevation="16" density="compact">
                <v-card-title>Filtrar Por</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-select density="compact" label="Filtrar Por" v-model="filtrarPor" :items="itemsFiltrarPor"
                                item-title="label" item-value="value"></v-select>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="8">
            <v-card elevation="16" density="compact">
                <v-card-title color="primary">Mostrar</v-card-title>
                <v-card-text density="compact">
                    <v-row>
                        <v-col cols="4">
                            <v-checkbox density="compact" v-model="abertas" label="Abertas" @change="filtrar()"></v-checkbox>
                        </v-col>
                        <v-col cols="4">
                            <v-checkbox density="compact" v-model="canceladas" label="Canceladas" @change="filtrar()"></v-checkbox>
                        </v-col>
                        <v-col cols="4">
                            <v-checkbox density="compact" v-model="finalizadas" label="Finalizadas" @change="filtrar()"></v-checkbox>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <v-card elevation="16">
                <v-card-title>Filtro</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="2">
                            <Data compact label="Data Inicial" type="text" v-model="dataInicial"></Data>
                        </v-col>
                        <v-col cols="2">
                            <Data label="Data Final" type="text" v-model="dataFinal"></Data>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field id="codigoCliente" label="Cód.Cliente" 
                                type="text" v-model="codigoCliente" @blur="getCliente()"
                                v-tooltip="'F5-Abre a busca de clientes.'">    
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Nome do Cliente" type="text" v-model="nomeCliente" readonly tabindex="-1">
                                <template v-slot:append>
                                    <v-tooltip text="Faz a busca de um cliente por nome apelido/CPF.">
                                        <template v-slot:activator="{ props }">
                                            <v-btn v-bind="props" color="primary" @click="dialogCliente = true" id="btnBuscaCliente">
                                                <v-icon>mdi-magnify</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-tooltip>
                                    <v-dialog v-model="dialogCliente" transition="dialog-top-transition">
                                        <ClienteBusca 
                                            @selecionouCliente="selecionouCliente" 
                                            @close-dialog="dialogCliente = false"
                                            :select-inativo-bloqueado=true />
                                    </v-dialog>

                                    &nbsp;&nbsp;
                                    <v-btn color="primary" @click.prevent="filtrar('geral')" prepend-icon="mdi-magnify">Filtrar</v-btn>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <v-card elevation="16">
                <v-card-title>Busca Rápida</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="4">
                            <v-text-field label="Código" type="text" v-model="codigo">
                                <template v-slot:append>
                                    <v-btn color="primary" @click.prevent="filtrar('codigo')" prepend-icon="mdi-magnify">Filtrar</v-btn>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-select label="" v-model="campo" :items="labelCampos"
                                item-title="label" item-value="value">
                            </v-select>
                        </v-col>
                        <v-col cols="5">
                            <v-text-field label="" type="text" v-model="buscaCampo">
                                <template v-slot:append>
                                    <v-btn color="primary" @click.prevent="filtrar('campo')" prepend-icon="mdi-magnify">Filtrar</v-btn>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <p></p>
    <v-card elevation="16" class="mt-4">
        <v-table density="compact">
            <thead>
                <tr>
                    <th class="text-center">Ações</th>
                    <th>Situação</th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('codigo')">
                            Código
                            <v-icon v-if="orderBy == 'codigo'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>Forma Pagamento</th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('codigoCliente')">
                            Cód.Cliente
                            <v-icon v-if="orderBy == 'codigoCliente'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('nomeCliente')">
                            Nome do Cliente
                            <v-icon v-if="orderBy == 'nomeCliente'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('data')">
                            D.Emissão
                            <v-icon v-if="orderBy == 'data'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('hora')">
                            Hora Emissão
                            <v-icon v-if="orderBy == 'hora'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('prazoEntrega')">
                            Prazo Entrega
                            <v-icon v-if="orderBy == 'prazoEntrega'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('dataFinalizacao')">
                            Data Finalização
                            <v-icon v-if="orderBy == 'dataFinalizacao'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('horaFinalizacao')">
                            Hora Finalização
                            <v-icon v-if="orderBy == 'horaFinalizacao'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>

                    <th>Valor Total</th>
                    <th>{{ labelCampo1 }}</th>
                    <th>{{ labelCampo2 }}</th>
                    <th>{{ labelCampo3 }}</th>
                    <th>{{ labelCampo4 }}</th>
                    <th>{{ labelCampo5 }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="o in ordensServicos" :key="o">
                    <td :class="o.situacao == 'C' ? 'text-red' : o.situacao == 'A' ? 'text-green': ''">
                        <v-tooltip text="Continua a ordem de serviços.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-invoice-edit-outline"
                                    v-show="temPermissao('03.03.02') && o.situacao !== 'F' && o.situacao !== 'C'"
                                    :to="{ name: 'OrdensServicos.Continuar', params: { id: o.idOrdemServicos } }">
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Cancela a ordem de serviços.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-cancel"
                                    v-show="temPermissao('03.03.04') && (o.situacao == 'F' || o.situacao == 'A' )"
                                    @click.prevent="openModalCancelar(o)"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Exclui a ordem de serviços.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-trash-can"
                                    v-show="temPermissao('03.03.05')"
                                    @click.prevent="openModalDelete(o)"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Consulta a ordem de serviços.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-tab-search"
                                    v-show="temPermissao('03.03.03')"
                                    :to="{ name: 'OrdensServicos.Show', params: { id: o.idOrdemServicos } }"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Imprime a ordem de serviços.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-printer-outline"
                                    v-show="temPermissao('03.03.07') && o.situacao !== 'C'"
                                    @click.prevent="imprimir(o.idOrdemServicos)"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Duplica a ordem de serviços.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-content-duplicate"
                                    v-show="temPermissao('03.03.06')"
                                    @click.prevent="duplicar(o.idOrdemServicos)"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Altera o código da ordem de serviços.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-pencil"
                                    v-show="temPermissao('03.03.08')"
                                    @click.prevent = "dialogAlterarCodigoOrdemServicos = true; auxIdOrdemServicos = o.idOrdemServicos; auxCodigoAntigo = o.codigo"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                    </td>
                    <td :class="o.situacao == 'C' ? 'text-red' : o.situacao == 'A' ? 'text-green': ''">{{ o.situacaoExtenso }}</td>
                    <td :class="o.situacao == 'C' ? 'text-red' : o.situacao == 'A' ? 'text-green': ''">{{ o.codigo }}</td>
                    <td :class="o.situacao == 'C' ? 'text-red' : o.situacao == 'A' ? 'text-green': ''">{{ o.formaPagamentoExtenso }}</td>
                    <td :class="o.situacao == 'C' ? 'text-red' : o.situacao == 'A' ? 'text-green': ''">{{ o.codigoCliente }}</td>
                    <td :class="o.situacao == 'C' ? 'text-red' : o.situacao == 'A' ? 'text-green': ''">{{ o.nomeCliente }}</td>
                    <td :class="o.situacao == 'C' ? 'text-red' : o.situacao == 'A' ? 'text-green': ''">{{ o.data }}</td>
                    <td :class="o.situacao == 'C' ? 'text-red' : o.situacao == 'A' ? 'text-green': ''">{{ o.hora }}</td>
                    <td :class="o.situacao == 'C' ? 'text-red' : o.situacao == 'A' ? 'text-green': ''">{{ o.prazo }}</td>
                    <td :class="o.situacao == 'C' ? 'text-red' : o.situacao == 'A' ? 'text-green': ''">{{ o.dataFinalizacao }}</td>
                    <td :class="o.situacao == 'C' ? 'text-red' : o.situacao == 'A' ? 'text-green': ''">{{ o.horaFinalizacao }}</td>
                    <td :class="o.situacao == 'C' ? 'text-red' : o.situacao == 'A' ? 'text-green': ''">{{ valorFormat(o.valorTotal) }}</td>
                    <td :class="o.situacao == 'C' ? 'text-red' : o.situacao == 'A' ? 'text-green': ''">{{ o.campo1 }}</td>
                    <td :class="o.situacao == 'C' ? 'text-red' : o.situacao == 'A' ? 'text-green': ''">{{ o.campo2 }}</td>
                    <td :class="o.situacao == 'C' ? 'text-red' : o.situacao == 'A' ? 'text-green': ''">{{ o.campo3 }}</td>
                    <td :class="o.situacao == 'C' ? 'text-red' : o.situacao == 'A' ? 'text-green': ''">{{ o.campo4 }}</td>
                    <td :class="o.situacao == 'C' ? 'text-red' : o.situacao == 'A' ? 'text-green': ''">{{ o.campo5 }}</td>
                </tr>
            </tbody>
        </v-table>

        <v-dialog v-model="dialogAlterarCodigoOrdemServicos" transition="dialog-top-transition" width="auto">
            <AlterarCodigoOrdemServicos :idOrdemServicos="auxIdOrdemServicos" :codigoAntigo="auxCodigoAntigo" 
                @close-dialog="dialogAlterarCodigoOrdemServicos = false" 
                @alterou-codigo="dialogAlterarCodigoOrdemServicos = false;filtrar()" />
        </v-dialog> 

    </v-card>

    <div class="block text-right">
        <v-pagination @update:modelValue="handleCurrentChange" v-model="currentPage" :length="Math.ceil(total / pageSize)" :total-visible="7" :show-first-last-page="true">
        </v-pagination>
    </div>
    <p></p>
    <v-row>
        <v-col>
            <v-btn v-show="temPermissao('03.03.01')" color="primary" :to="{ name: 'OrdensServicos.Create' }">Nova Ordem de Serviços</v-btn>
        </v-col>
    </v-row>
</template>
