<script setup>
    import { ref, onMounted, defineEmits, defineProps, inject } from 'vue';
    import { ContaReceber } from "../../resources/contaReceberResource";
    import { useRoute } from "vue-router";
    import { valorFormat} from "../../services/filtros";
    import MsgConfirm from "../Utils/MsgConfirm";

    let recebimentos = ref({});
    const route = useRoute();
    const msgConfirmRecebimento = ref(null);
    const emit = defineEmits(['close-dialog', 'excluiu-recebimento']);
    const snackBarApp = inject("snackBarApp");
    
    onMounted(() => {
        getRecebimentos();
    });

    const props = defineProps({
        idLancamento: {
            type: Number,
            
        },
    });


    async function getRecebimentos() {
        let dados = new FormData();
        if(route.name == 'ContasReceber.List') {
            dados.append('idContaReceber', props.idLancamento);
        }
        else {
            dados.append('idContaReceber', route.params.id);
        }
        
        if(route.name == 'ContasReceber.Quita') {
            dados.append('codigoModulo', '03.02.04');
        }
        if(route.name == 'ContasReceber.Update') {
            dados.append('codigoModulo', '03.02.03');
        }
        if(route.name == 'ContasReceber.Show') {
            dados.append('codigoModulo', '03.02.09');
        }
        if(route.name == 'ContasReceber.List') {
            dados.append('codigoModulo', '03.02.08');
        }
        let response = await  ContaReceber.getRecebimentosById(dados);
        if (response.status == 200) {
            recebimentos.value = response.data.data;
        }
    }


    function closeModalRecebimentos() {
        emit('close-dialog');
    }

    function openModalExcluirRecebimento(lancamento) {
        msgConfirmRecebimento.value
            .open(
                "Confirma Exclusão ?",
                "Confirma a exclusão desse recebimento ?",
                { color: "primary", zIndex: 5000  }
            )
            .then((confirm) => {
                if (confirm) {
                    excluirRecebimento(lancamento);
                }
        });
    }

    async function excluirRecebimento(idRecebimentoContaReceber) {
        let dados = new FormData();
        dados.append("idRecebimentoContaReceber", JSON.stringify(idRecebimentoContaReceber));

        try {
            const response = await ContaReceber.excluirRecebimento(dados);
                if (response.status == 200) {
                    if (response.data.success == "true") {
                        snackBarApp.value.open(response.data.message, 15000);
                        getRecebimentos();
                        emit('excluiu-recebimento');
                    }
                }
        } catch (err) {
            console.log(err);
        }
    }
</script>

<template>
    <v-card>
        <v-card-title>
            <h3 v-if="route.name !== 'ContasReceber.List'">Recebimentos de um Lançamento de Contas a Receber</h3>
            <h3 v-if="route.name == 'ContasReceber.List'">Exclui Recebimentos de um Lançamento de Contas a Receber</h3>
        </v-card-title>
        <v-card-text>
            <v-card  elevation="16" class="mb-5">
                <v-card-text>
                    <v-table density="compact">
                        <thead>
                        <tr>
                            <th v-if="route.name=='ContasReceber.List'">Ação</th>
                            <th>Data Recebimento</th>
                            <th>Valor Recebido</th>
                            <th>Juros Recebido</th>
                            <th>Multa Recebida</th>
                            <th>Forma de Recebimento</th>
                            <th>Conta</th>
                            <th>Cartão Convênio</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(o) in recebimentos" :key="o">
                                <td v-if="route.name=='ContasReceber.List'">
                                    <v-tooltip text="Exclui o recebimento.">
                                        <template v-slot:activator="{ props }">
                                            <v-btn
                                                size="small"
                                                v-bind="props"
                                                variant="text"
                                                icon="mdi-trash-can"
                                                @click.prevent="openModalExcluirRecebimento(o.idRecebimentoContaReceber)">
                                            </v-btn>
                                        </template>
                                    </v-tooltip>
                                </td>
                                <td>{{ o.data }}</td>
                                <td>{{ valorFormat(o.valor) }}</td>
                                <td>{{ valorFormat(o.jurosRecebido) }}</td>
                                <td>{{ valorFormat(o.multaRecebida) }}</td>
                                <td>{{ o.formaPagamentoExtenso }}</td>
                                <td>{{ o.nomeConta }}</td>
                                <td>{{ o.nomeCartao }}</td>

                            </tr>
                        </tbody>
                    </v-table>
                </v-card-text>
                
            </v-card>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" variant="elevated" @click.prevent='closeModalRecebimentos'>Fechar</v-btn>
        </v-card-actions>
    </v-card>
    <MsgConfirm ref="msgConfirmRecebimento"></MsgConfirm>
</template>
