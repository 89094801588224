<script setup>
import { ref, defineEmits, defineProps, inject } from "vue";
import AVistaOrdemServicos  from "./AVistaOrdemServicos.vue";
import APrazoOrdemServicos  from "./APrazoOrdemServicos.vue";
import ChequeOrdemServicos  from "./ChequeOrdemServicos.vue";
import CartaoOrdemServicos from "./CartaoOrdemServicos.vue";
import DepositoOrdemServicos from "./DepositoOrdemServicos.vue";

const props = defineProps({
    ordemServicos: {
        type: Object,
    },
});

const emit = defineEmits(['close-dialog', 'finalizou-ordem-servicos']);
const dialogFinalizaAVista = ref(false);
const dialogFinalizaAPrazo = ref(false);
const dialogFinalizaCheque = ref(false);
const dialogFinalizaCartao = ref(false);
const dialogFinalizaDeposito = ref(false);

const snackBarApp = inject('snackBarApp');

const ordemServicos = ref(props.ordemServicos);

function closeModalFormaPagamentoOrdemServicos() {
    emit('close-dialog');
}

function abreFinalizaAVista() {
    dialogFinalizaAVista.value = true;
}

function abreFinalizaAPrazo() {
    if(ordemServicos.value.idCliente <= 0) {
        snackBarApp.value.open('Nenhum cliente selecionado. Para finalizar uma ordem de serviços a prazo é necessário que a '+
            'ordem de serviços tenha um cliente.');
        return;
    }
    dialogFinalizaAPrazo.value = true;
}

function abreFinalizaCheque() {
    if(ordemServicos.value.idCliente <= 0) {
        snackBarApp.value.open('Nenhum cliente selecionado. Para finalizar uma ordem de serviços com cheque é necessário que a '+
            'ordem de serviços tenha um cliente.');
        return;
    }
    dialogFinalizaCheque.value = true;
}

function abreFinalizaCartao() {
    dialogFinalizaCartao.value = true;
}

function abreFinalizaDeposito() {
    dialogFinalizaDeposito.value = true;
}

function finalizouOrdemServicos(formaPagamento) {
    if(formaPagamento == 'CAI') {
        dialogFinalizaAVista.value = false;
    }

    if(formaPagamento == 'PRA') {
        dialogFinalizaAPrazo.value = false;
    }

    if(formaPagamento == 'CHE') {
        dialogFinalizaCheque.value = false;
    }

    if(formaPagamento == 'CAR') {
        dialogFinalizaCartao.value = false;
    }

    if(formaPagamento == 'DEP') {
        dialogFinalizaDeposito.value = false;
    }

    emit('finalizou-ordem-servicos', formaPagamento);

}

</script>

<template>
    <v-card elevetion="16">
        <v-card-title>
            <h2>Forma de Pagamento</h2>
        </v-card-title>
        <v-card-text align="center">
            <v-btn color="primary" variant="elevated" @click="abreFinalizaAVista()">A Vista</v-btn>&nbsp;&nbsp;
            <v-btn color="primary" variant="elevated" @click="abreFinalizaAPrazo()">A Prazo</v-btn>&nbsp;&nbsp;
            <v-btn color="primary" variant="elevated" @click="abreFinalizaCheque()">Cheque</v-btn>&nbsp;&nbsp;
            <v-btn color="primary" variant="elevated" @click="abreFinalizaCartao()">Cartão/Convênio</v-btn>&nbsp;&nbsp;
            <v-btn color="primary" variant="elevated" @click="abreFinalizaDeposito()">PIX/Transferência/Depósito</v-btn>&nbsp;&nbsp;
            <v-btn color="primary" variant="elevated" @click="closeModalFormaPagamentoOrdemServicos()">Fechar</v-btn>

            <v-dialog v-model="dialogFinalizaAVista"  transition="dialog-top-transition">
                <AVistaOrdemServicos :propOrdemServicos="ordemServicos" operacao = "F"
                    @close-dialog="dialogFinalizaAVista = false"
                    @finalizou-ordem-servicos="finalizouOrdemServicos"></AVistaOrdemServicos>
            </v-dialog>

            <v-dialog v-model="dialogFinalizaAPrazo"  transition="dialog-top-transition">
                <APrazoOrdemServicos :propOrdemServicos="ordemServicos" operacao = "F"
                    @close-dialog="dialogFinalizaAPrazo = false"
                    @finalizou-ordem-servicos="finalizouOrdemServicos"></APrazoOrdemServicos>
            </v-dialog>

            <v-dialog v-model="dialogFinalizaCheque"  transition="dialog-top-transition">
                <ChequeOrdemServicos :propOrdemServicos="ordemServicos" operacao = "F"
                    @close-dialog="dialogFinalizaCheque = false"
                    @finalizou-ordem-servicos="finalizouOrdemServicos"></ChequeOrdemServicos>
            </v-dialog>

            <v-dialog v-model="dialogFinalizaCartao"  transition="dialog-top-transition">
                <CartaoOrdemServicos :propOrdemServicos="ordemServicos" operacao = "F"
                    @close-dialog="dialogFinalizaCartao = false"
                    @finalizou-ordem-servicos="finalizouOrdemServicos"></CartaoOrdemServicos>
            </v-dialog>

            <v-dialog v-model="dialogFinalizaDeposito"  transition="dialog-top-transition">
                <DepositoOrdemServicos :propOrdemServicos="ordemServicos" operacao = "F"
                    @close-dialog="dialogFinalizaDeposito = false"
                    @finalizou-ordem-servicos="finalizouOrdemServicos"></DepositoOrdemServicos>
            </v-dialog>
        </v-card-text>
    </v-card>
</template>
