<script setup>
import { onMounted, inject, defineEmits, ref, defineProps } from "vue";
import { string, object, addMethod } from "yup";
import { OrdemServicos } from "../../resources/ordemServicosResource";
import { Conta } from "../../resources/contaResource";
import { Cartao } from "../../resources/cartaoResource";
import { useField, useForm } from "vee-validate";
import { Converte } from "@/utils/converte";
import { useRoute } from "vue-router";
import Data from "../Utils/data.vue";
import Moeda from "../Utils/moeda.vue";
import MsgConfirm from "../Utils/MsgConfirm";
import { valorFormat, dateFormat, formaRecebimentoAdiantamentoFormat } from "../../services/filtros";
import { ReciboCliente } from "../../resources/reciboClienteResource";
import { Relatorio } from "../../resources/relatoriosResource";
import config from "../../services/config";

let msgConfirm = ref(null);
const snackBarApp = inject("snackBarApp");
const route = useRoute();
const emit = defineEmits(['close-dialog', 'alterou-adiantamento']);
var valorTotalAdiantamentos = ref(0);

const idCliente = ref(null);
const codigoOrdemServicos = ref(null);

const contas = ref([]); 
const detalhesConta = ref('');

const cartoes = ref([]);

const props = defineProps({
    propIdCliente: {
        type: Number,
    },
    propCodigoOrdemServicos: {
        type: Number,
    },
});

addMethod(string, "valor_maior_zero", function valorMaiorZero(message) {
    return string().test(
        "valor-maior-zero", // Name
        message, // Msg
        function (valor) {
            if(Converte.stringToFloat(valor) <= 0) {
                return false;
            }
            return true;
        }
    );
});

const validationSchema = object({
    adiantamento: object({
        idOrdemServicos: undefined,
        data: string().required().label("O campo da data do adiantamento"),
        formaRecebimento: undefined,
        valor: string().required("O valor é obrigatório").valor_maior_zero("O valor deve ser maior que zero"),
        idConta: undefined,
        idCartao: undefined,
    }).required(),
});

const adiantamentos = ref([]);

const { handleSubmit, errors } = useForm({ validationSchema });

const adiantamento = ref({
    idOrdemServicos: useField("adiantamento.idOrdemServicos").value,
    data: useField("adiantamento.data").value,
    formaRecebimento: useField("adiantamento.formaRecebimento").value,
    valor: useField("adiantamento.valor").value,
    idConta: useField("adiantamento.idConta").value,
    idCartao: useField("adiantamento.idCartao").value,
});

const readOnlyAdiamento = ref(false);

const submit = handleSubmit((values, actions) => {
    gravar(values, actions);
});

const itemsFormaRecebimento = ref([
    { text: "Dinheiro", value: "DIN" },
    { text: "Cartão/Convênio", value: "CAR" },
    { text: "PIX/Depósito/Transferência", value: "DEP" },
]);

onMounted(() => {
    idCliente.value = props.propIdCliente;
    codigoOrdemServicos.value = props.propCodigoOrdemServicos;  
    adiantamento.value.idOrdemServicos = route.params.id;
    getAdiantamento();
    getContas();
    getCartoes();
    document.getElementById("dataAdiantamento").focus();

    if(route.name == 'OrdensServicos.Show') {
        readOnlyAdiamento.value = true;
    }
});

async function getCartoes() {
    try {
        
        let dados = new FormData();
        dados.append('soAtivos', true);

        let response = await Cartao.getCartoes(dados);
        cartoes.value = response.data.data;
        if (adiantamento.value.idCartao == null) {
            adiantamento.value.idCartao = response.data.data[0].idCartao;
            return;
        }
    }
    catch (err) {
        snackBarApp.value.open('Erro ao buscar os cartões.');
        console.log(err);
    }
}

async function getAdiantamento() {
    if(route.params.id == null) {
        return;
    }

    let dados = new FormData();
    dados.append('idOrdemServicos', route.params.id);
    dados.append('codigoModulo', '03.03.02');

    adiantamento.value.data = new Date().toLocaleDateString("pt-BR");
    adiantamento.value.valor = 'R$ 0,00';
    adiantamento.value.formaRecebimento = 'DIN';

    try {
        let response = await OrdemServicos.getAdiantamento(dados)
        if (response.status == 200) {
            adiantamentos.value = response.data.data;

            valorTotalAdiantamentos.value = 0;
            adiantamentos.value.forEach(registro => {
                valorTotalAdiantamentos.value += parseFloat(registro.valor);
            });
        }
    }
    catch (err) {
        console.log(err);
    }
}

async function gravar(values) {
    try {
        let dados = new FormData();

        values.adiantamento.data = Converte.datePtBrToIso(values.adiantamento.data);

        dados.append('dados', JSON.stringify(values.adiantamento));

        let response = await OrdemServicos.gravarAdiantamento(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open('Não foi possível gravar o adiantamento, '+ response.data.message);
                return;
            }

            snackBarApp.value.open('Adiantamento gravado com sucesso.');
            emit('alterou-adiantamento');
            getAdiantamento();
            
            // emitir recibo
            if(idCliente.value > 0) {
                openModalGerarRecibo(values.adiantamento.idOrdemServicos, values.adiantamento.data, values.adiantamento.valor, 
                idCliente, 'Adiantamento da ordem de serviços, código:'+ codigoOrdemServicos.value);

            }


        }
    }
    catch (err) {
        snackBarApp.value.open('Erro ao gravar o adiantamento.');
        console.log(err);
    }
}

async function getContas() {
    const response = await Conta.getContas();
    contas.value = response.data.data;

    if (contas.value.length > 0 && (adiantamento.value.idConta == null || adiantamento.value.idConta <= 0)) {
        adiantamento.value.idConta = response.data.data[0].idConta;
    }

    changeConta();
}

async function changeConta() {
    let dados = new FormData();
    dados.append('codigoModulo', '03.03.02');
    dados.append('idConta', adiantamento.value.idConta);
    try {
        const response = await Conta.getById(dados);
        if (response.status == 200) {
            let conta = response.data.data;

            detalhesConta.value = 'Banco:'+conta.nomeBanco+' agência:'+conta.agencia+' nro.conta:'+conta.conta+' saldo atual:'+Converte.numberToCurrency(conta.saldo);

            if(conta == []) {
                detalhesConta.value = "";
            }
        }
    }
    catch (err) {
        console.log(err);
    }
}

async function destroy(idAdiantamentoOrdemServicos) {
    let dados = new FormData();
    dados.append("idAdiantamentoOrdemServicos", JSON.stringify(idAdiantamentoOrdemServicos));

    try {
        const response = await OrdemServicos.excluirAdiantamento(dados);
        if (response.status == 200) {
            if (response.data.success == true) {
                snackBarApp.value.open("Adiantamento excluído com sucesso.");
                getAdiantamento();
                emit('alterou-adiantamento');
            }
        }
    } catch (err) {
        console.log(err);
    }
}

function openModalDelete(lancamento) {
    msgConfirm.value
        .open(
            "Confirma Exclusão ?",
            "Confirma a exclusão desse adiantamento ?",
            { color: "primary",
              zIndex: 7000
                
            }
        )
        .then((confirm) => {
            if (confirm) {
                destroy(lancamento.idAdiantamentoOrdemServicos);
            }
        });
}

function openModalGerarRecibo(idContaReceber, data, valor, idCliente, descricao) {
    msgConfirm.value
        .open(
            "Gerar Recibo ?",
            "Deseja gerar um recibo para o cliente ?",
            { 
                color: "primary",
                zIndex: 7000
             }
        )
        .then((confirm) => {
            if (confirm) {
                gerarRecibo(idContaReceber, data, valor, idCliente, descricao);
            }
        });
}

async function gerarRecibo(idContaReceber, data, valor, idCliente, descricao) {
    let obj =  {};
    obj.codigo = null;
    obj.idModulo = idContaReceber;
    obj.modulo = 'OS';
    obj.data = data;
    obj.valor = valor;
    obj.idCliente = idCliente;
    obj.descricao = descricao;
    
    let dados = new FormData();
    dados.append('data', JSON.stringify(obj));

    try {
        const response = await ReciboCliente.save(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open("O recibo não pode ser gerado, mensagem de erro: " + response.data.message, 6000);
                return;
            }
            const idReciboCliente = response.data.data.idReciboCliente;
            let nomeRel = 'Recibo Cliente';

            let responseRel = await Relatorio.get(nomeRel, idReciboCliente);
            if (responseRel.status == 200) {
                if (responseRel.data.success == true) {
                    let nomeArquivo = responseRel.data.nomeArquivo;
                    let url = config.baseUrlRel + nomeArquivo;

                    window.open(url, '_blank');
                    return;

                }
                if (response.data.success == false) {
                    snackBarApp.value.open(responseRel.data.message);
                    return;
                }

                snackBarApp.value.open("Não foi possível fazer a impressão do recibo, tente novamente.");
            }

        }
    } catch (err) {
        snackBarApp.value.open("Erro, mensagem de erro: " + err);
    }
}


</script>

<template>
    <MsgConfirm ref="msgConfirm"></MsgConfirm>
    <v-card elevation="16">
        <v-card-title>
            <h2>Adiantamentos de Ordem de Serviços</h2>
        </v-card-title>
        <v-card-text>
            <form ref="formAdiantamentosOrdemServicos" @submit.prevent="submit">
                <v-card elevation="16" class="mb-4" v-show="route.name=='OrdensServicos.Continuar'">
                    <v-card-title>
                        <h4>Registrar Novo Adiantamento</h4>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="3">
                                <Data label="Data do Adiantamento"
                                    id="dataAdiantamento"
                                    v-model="adiantamento.data"
                                    :error-messages="errors['adiantamento.data']"
                                    :readOnly="readOnlyAdiamento"></Data>
                            </v-col>
                            <v-col cols="4">
                                <Moeda label="Valor do Adiantamento" id="valorAdiantamento" type="text"
                                    v-model="adiantamento.valor"
                                    :error-messages="errors['adiantamento.valor']" maxlength="15"
                                    :readOnly="readOnlyAdiamento"/>
                            </v-col>
                            <v-col cols="5">
                                <v-select
                                    v-model="adiantamento.formaRecebimento"
                                    :items="itemsFormaRecebimento"
                                    item-title="text"
                                    item-value="value"
                                    label="Forma de Recebimento"
                                    :error-messages="errors['adiantamento.formaRecebimento']"
                                    :readOnly="readOnlyAdiamento"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row v-show="adiantamento.formaRecebimento == 'DEP'">
                            <v-col cols="4">
                                <v-select v-model="adiantamento.idConta"  :items="contas" 
                                    item-title="apelido" item-value="idConta" @update:modelValue="changeConta()" label="Conta" 
                                    no-data-text="Nenhuma Conta Bancária"></v-select>
                            </v-col>    
                            <v-col cols="8">
                                <v-text-field label="Detalhes da Conta" v-model="detalhesConta" readonly tabindex=-1></v-text-field>
                            </v-col>    
                        </v-row>
                        <v-row v-show="adiantamento.formaRecebimento == 'CAR'">
                            <v-col cols="12">
                                <v-select v-model="adiantamento.idCartao"  :items="cartoes" 
                                    item-title="nome" item-value="idCartao" label="Cartão/Convênio" 
                                    no-data-text="Nenhum Cartão/Convênio Cadastrado"></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn v-show="route.name=='OrdensServicos.Continuar'" color="primary" variant="elevated"  type="submit" id="btnGravarAdiantamento">Salvar</v-btn>
                    </v-card-actions>
                </v-card>
            </form>    
            <v-card elevation="16">
                <v-card-title>
                    <h4>Adiantamentos</h4>
                </v-card-title>
                <v-card-text>
                    <v-table density="compact">
                        <thead>
                            <tr>
                                <th v-show="route.name == 'OrdensServicos.Continuar'" class="text-center">Ações</th>
                                <th>Data</th>
                                <th>Valor</th>
                                <th>Forma de Recebimento</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="o in adiantamentos" :key="o">
                                <td class="text-center" v-show="route.name == 'OrdensServicos.Continuar'">
                                    <v-tooltip text="Exclui o adiantamento.">
                                        <template v-slot:activator="{ props }">
                                            <v-btn
                                                size="small"
                                                v-bind="props"
                                                variant="text"
                                                icon="mdi-trash-can"
                                                @click.prevent="openModalDelete(o)"
                                            >
                                            </v-btn>
                                        </template>
                                    </v-tooltip>
                                </td>
                                <td>{{ dateFormat(o.data) }}</td>
                                <td>{{ valorFormat(o.valor) }}</td>
                                <td>{{ formaRecebimentoAdiantamentoFormat(o.formaRecebimento) }}</td>
                            </tr>
                        </tbody>
                    </v-table>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <h3 v-show="adiantamentos.length == 0">Nenhum adiantamento registrado.</h3>
                    <h3 v-show="adiantamentos.length > 0">Valor Total de Adiantamentos: {{ valorFormat(valorTotalAdiantamentos.toString()) }}</h3>
                </v-card-actions>
            </v-card>
        </v-card-text>

        <v-card-actions>
            <v-btn
                color="primary"
                variant="elevated"
                @click.prevent="emit('close-dialog')">
                Fechar
            </v-btn>
        </v-card-actions>
    </v-card>
</template>