import { useCasasDecimais } from '../store/casasDecimais';
export class Converte {

    static stringToFloat(valor){
        if(valor === "" || valor == undefined || valor == null){
            valor =  0;
        }else{
            valor = valor.replace(".","");
            valor = valor.replace("R$","");
            valor = valor.replace("%","");
            valor = valor.replace(",",".");
            valor = parseFloat(valor);
        }
        return valor;

    }

    static numberToCurrency(valor){
        if(valor == "") {
            valor = 0;
        }

        let result = new Intl.NumberFormat('pt-BR', {
            maximumFractionDigits:2,
            minimumFractionDigits:2,
            style: 'decimal',
        }).format(valor);

        result = 'R$ '+result;

        return result;
    }

    static numberToCurrencyDuasCasas(valor){
        if(valor == "") {
            valor = 0;
        }

        return new Intl.NumberFormat('pt-BR', {
            maximumFractionDigits:2,
            minimumFractionDigits:2,
            style: 'currency',
            currency: 'BRL'
        }).format(valor).replace(/^(\D+)/, '$1');

    }

    static numberToPCustoProduto(valor){
        const casasDecimaisStore = useCasasDecimais();
        if(valor == "") {
            valor = 0;
        }

        let result = new Intl.NumberFormat('pt-BR', {
            maximumFractionDigits:casasDecimaisStore.pCustoProduto,
            minimumFractionDigits:casasDecimaisStore.pCustoProduto,
            style: 'decimal',
            }).format(valor);

        result = 'R$ '+result;

        return result;
    }


    static numberToStringNumber(valor){
        if(valor == "") {
            valor = 0;
        }

        return new Intl.NumberFormat('pt-BR', {
            maximumFractionDigits:3,
            minimumFractionDigits:3,
            style: 'decimal'
        }).format(valor);

    }

    static numberToQuantidadeProduto(valor){
        const casasDecimaisStore = useCasasDecimais();
        if(valor == "") {
            valor = 0;
        }

        return new Intl.NumberFormat('pt-BR', {
            maximumFractionDigits:casasDecimaisStore.quantidadeProduto,
            minimumFractionDigits:casasDecimaisStore.quantidadeProduto,
            style: 'decimal'
        }).format(valor);

    }

    static numberToQuantidadeServico(valor){
        const casasDecimaisStore = useCasasDecimais();
        if(valor == "") {
            valor = 0;
        }

        return new Intl.NumberFormat('pt-BR', {
            maximumFractionDigits:casasDecimaisStore.quantidadeServico,
            minimumFractionDigits:casasDecimaisStore.quantidadeServico,
            style: 'decimal'
        }).format(valor);

    }

    static numberToPorcentagem(valor, casasDecimais=2){
        if(valor == "") {
            valor = 0;
        }

        return new Intl.NumberFormat('pt-BR', {
            maximumFractionDigits:casasDecimais,
            minimumFractionDigits:casasDecimais,
            style: 'decimal'
        }).format(valor) + ' %';

    }


    static codigoProduto(codigo){
        if(codigo == "") {
            return "";
        }

        codigo = codigo.trim();

        while(codigo.length < 15) {
            codigo = ' '+codigo;
        }

        return codigo;
    }

    static datePtBrToIso(date) {
        if(date == "") {
            return "";
        }

        if(date == null || date == undefined)
        {
            return null;
        }

        let data = date.split("/");
        return data[2]+'-'+data[1]+'-'+data[0];
    }


    static dateIsoToPtBr(date) {
        if (!date || date === '') return ''; // Return empty string if value is null or empty
    
        return date.slice(8,10)+'/'+date.slice(5,7)+'/'+date.slice(0,4);
        
    }    
}
